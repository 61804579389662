<template>
  <v-card
    class="uploadContentPanel"
    flat
  >
    <v-card-title>
      <v-row style="padding-top: 0;margin-top: 0;" dense>
        <v-col cols="1" align="center">
          <FileUploader :displayMode="displayMode" :fileUploadData="fileUploadFormData" :uploadDisabled="disableFileUpload" :multiple="multiUpload" :uploadedCallback="filesUploadedCallback"/>
        </v-col>
        <v-col cols="4">
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Start typing to search files..."
            single-line
            clearable
            class="ml-4 mr-4"
            hide-details
          ></v-text-field>
        </v-col> 
        <v-col cols="1" align="center">

          <v-badge
            :content="docCreationCount"
            :value="docCreationCount"
            color="primary"
            bordered
            overlap
          >
            <v-btn :disabled="selected.length === 0 && docCreationCount === 0" @click="openHierarchyDialog" color="success">
              Create
            </v-btn>
          </v-badge>
        </v-col>        
        <v-col cols="1" align="center">
          <v-btn
            color="red" :disabled="selected.length === 0" @click="openDeleteDialog" title="Delete File(s)"
          >
          <v-icon color="white" icon>
            mdi-delete
          </v-icon>  
          </v-btn>          
        </v-col>        
        <v-col cols="1" align="center" >
          <v-btn
            dark color="green"
            title="Excel File Configuration"
            v-if="anyExcelUploaded"
            @click="openExcelConfig"
          >
          <v-icon icon>
            mdi-microsoft-excel
          </v-icon>  
          </v-btn>
        </v-col>  
        <v-col cols="1">
          <v-btn
            dark color="primary"
            :title="progressDialog ? 'Hide Progress Panel' : 'View Process Panel'"
            @click="progressDialog = !progressDialog"
            v-if="useGateway && awaitingResponse"
          >
            <v-icon icon>
              mdi-progress-clock
            </v-icon>          
            <!--<v-icon icon>
              //mdi-console-line
            </v-icon>-->
          </v-btn>          

        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text style="padding-top:8px">
      <v-row v-if="viewMode==='List'" >
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            show-select
            item-key="file_id"
            :headers="headers"
            :items="filteredFileItems"
            :search="search"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
          >
          <template v-slot:[`item.docLinks`]="{ item }">
            <div v-if="item.docLinks.length > 0" @click="openLinkedFilesDialog(item.file_id)" style="cursor:pointer">
              <v-icon text style="padding-bottom: 3px" >
                  mdi-view-list
              </v-icon>                   
              {{item.docLinks.length}}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="downloadFile(item.file_id)"
            >
              mdi-cloud-download
            </v-icon>
          </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div v-if="console" style="max-height: 200px;
    height: 200px;
    overflow-y: scroll;
    background-color: rgb(60, 60, 60);
    color: white;
    font-size: 11px;
    width: 100%;
    margin-top: 12px;
    padding-left: 10px !important;
    padding-top: 10px;" >
        Events Console:
        <div v-for="(log,idx) in consoleLog" :key="idx">
          {{log}}<br/>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-row dense>
        <v-col dense cols="4"></v-col>
        <v-col dense cols="4" align="center" justify-center>
          <v-pagination
            style="padding-top:10px"
            v-model="page"
            :length="pageCount"
            :total-visible="5"
          ></v-pagination>      
        </v-col>


        <v-col dense cols="4" align="right">
          <v-select
            style="width:100px; margin-right:20px"
            v-model="itemsPerPage"
            :items="[10,15,25,50,100]"
          >
          </v-select>             
        </v-col>        
      </v-row>
    </v-card-actions> 
    <v-dialog 
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      persistent
      no-click-animation
      scrollable 
      v-model="hierarchiesDialog" 
      >
      <v-card class="uploadContentHierarchyPanel">
          <v-card-title class="justify-center">
            <v-row>
              <v-col cols="9" style="display:inline-flex">
                Document Creation Configuration 
                <v-btn class="ml-4" small color="success" :disabled='isCreating' @click="addHierarchyConfigGroup">
                  Add New Group 
                  <v-icon class="ml-1" small>mdi-plus-circle</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3">
                Selected Files
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>

            <v-row dense>
              <v-col class="colFixed" cols="9">   
                <v-card flat>
                <v-row class="hierarchyGroupRow" v-for="(item,index) in hierarchyGroups" :key="item.index">
                  <v-col cols="12">
                    <v-row style="cursor:pointer" dense class="hierarchyGroupHeader">
                      <v-col cols="3" @click="item.expanded = !item.expanded">
                        Document Creation Group {{index+1}} <span v-if="item.items.length > 0">({{item.items.length}} Document{{item.items.length === 1 ? '' : 's'}})</span> <span v-if="!item.expanded && item.doc_type !== ''"> - {{item.doc_type}}</span>
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox style="display:none" dense v-model="item.selectHierarchies" label="Select Hierarchies?"></v-checkbox>
                      </v-col>
                      <v-col cols="6" @click="item.expanded = !item.expanded" align="right">
                        <v-icon v-if="item.expanded">
                          mdi-chevron-up
                        </v-icon>
                        <v-icon v-else >
                          mdi-chevron-down
                        </v-icon>
                      </v-col> 
                      <v-col cols="1" align="right">
                        <v-icon v-if="index > 0" @click="deleteGroup(index)">
                          mdi-delete
                        </v-icon>                      
                      </v-col> 
                    </v-row>
                    <v-row v-if="item.expanded">
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="3">
                            <v-row dense>
                              <v-col cols="12">
                                <v-select label="Document Type" v-model="item.doc_type" @change="docTypeChange(item)" :items="docTypes"></v-select>
                              </v-col>
                            </v-row>                      
                            <v-row dense>
                            <v-col
                              cols="12"
                              v-if="
                                item.selectedTemplate &&
                                item.selectedTemplate.lifecycles.length > 1 
                                && !item.isActionSelected
                              "
                            >
                              <v-radio-group
                                v-model="item.selectedActionId"
                                row
                                v-if="item.selectedTemplate.lifecycles.length > 2"
                              >
                                <v-radio
                                  v-for="(l, li) in item.selectedTemplate.lifecycles"
                                  :key="'l' + li"
                                  :label="l.lifecycle_selection_text || 'Default'"
                                  :value="l.lifecycle_action_id"
                                ></v-radio>
                              </v-radio-group>
                              <v-switch
                                v-else
                                v-model="item.selectedActionNonDefault"
                                inset
                                :label="item.selectedTemplate.lifecycles[1].lifecycle_selection_text"
                              ></v-switch>
                            </v-col>
                            </v-row>                            
                          </v-col>
                          <v-col cols="6">
                            <v-row 
                                v-for="(h, hi) in item.hierarchyData"
                                :key="'h' + hi" dense  >
                              <v-col
                                cols="12"
                                class="expand"
                                v-if="item.selectHierarchies"
                              >
                                <v-row dense v-if="h.customHierarchy">
                                  <v-col cols="10">
                                      {{h.customData.join(" > ")}}
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon @click="deleteCustomHierarchyData(h)">
                                      mdi-close
                                    </v-icon>
                                  </v-col>
                                </v-row>                              
                                <v-row dense v-else>
                                  <v-col cols="10">
                                    <HierarchyTreePicker
                                      :options="h.treePickerOptionsActive"
                                      :label="h.label"
                                      placeholder="Select..."
                                      :fulldetail="h.position=== 1 ? true : false"
                                      :hierarchyType="h"
                                      dense
                                      @change="onHrySelect(item,h,$event)"
                                      :disabled='isCreating'
                                    >
                                    </HierarchyTreePicker>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-btn @click="addNewHierarchy(index,h)">Add New</v-btn>
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>
                          <v-col cols="3">
                            <draggable tag="ul" :list="item.items" :class="[isCreating ? 'dragZoneDisabled' : 'dragZone']"  :disabled='isCreating'
                              :group="{ name: 'row' }" :id="`list_` + item.index" >
                              <li
                                v-for="item in item.items"
                                :key="item.file_id"
                                class="draggableFileItem"
                              >
                                <v-icon class="handle">
                                  mdi-file
                                </v-icon>

                                <span class="text">{{ item.file_name }} </span>
                              </li>
                            </draggable>  
                          </v-col>
                        </v-row>
                        <v-row style="padding-top:15px;" dense>
                          <v-col cols="3">
                            <v-icon>mdi-cog</v-icon>&nbsp; Document Creation Options:
                          </v-col>
                        </v-row>                         
                        <v-row style="padding-top:5px;" dense>
                          <v-col cols="2">
                            <v-checkbox dense v-model="item.create_empty" label="Categorise Document?"></v-checkbox>
                          </v-col>
                          <v-col v-if="item.create_empty" cols="10" style="padding-top:10px">
                            <span>Please Note: Selecting this option will attempt to run document text through the Categoriser service!</span>
                          </v-col>
                        </v-row>                   
                        <v-row dense v-if="enabledTranslate && item.create_empty" style="padding-top:5px;">
                          <v-col cols="2">
                            <v-checkbox dense v-model="item.translate" label="Translate Document?"></v-checkbox>
                          </v-col>
                          <v-col cols="5">
                            <v-select dense v-model="item.target_lang" v-if="item.translate" :items="translateOptions" label="Target Language">
                              <template slot="item" slot-scope="item">
                                <div>
                                  <country-flag :country='getFlagCode(item)' size='normal'/><span style="padding-left: 9px; padding-bottom: 5px; vertical-align: middle;">{{item.item.text}}</span>
                                </div>
                              </template>
                              <template v-slot:selection="item">
                                  <country-flag :country='getFlagCode(item)' size='normal'/><span style="padding-left: 9px; padding-top: 10px;">{{item.item.text}}</span>
                              </template>   
                            </v-select>
                          </v-col>   
                        </v-row>  
                        <v-row dense v-if="itemContainsExcel(item)" style="padding-top:10px;">
                          <v-col cols="2"  style="padding-left:10px; padding-top:13px">Total Rows: {{item.items[0].rowCount}}</v-col>
                          <v-col cols="1"><v-text-field dense @keydown="isNumber($event)" :min="1" :max="item.items[0].rowCount" type="number" label="Start Row:" v-model="item.items[0].startRow"></v-text-field></v-col>  
                          <v-col cols="1"><v-text-field dense @keydown="isNumber($event)" :min="1" :max="item.items[0].rowCount"  type="number" label="End Row:"  v-model="item.items[0].endRow"></v-text-field></v-col>  
                          <v-col cols="3" style="padding-left: 25px; padding-top: 7px;" v-if="!excelConfigWarning">
                            <v-checkbox dense v-model="item.useExcelData" @click="validateExcel(item)" label="Use Excel Hierarchy Data?"></v-checkbox>
                          </v-col>
                          <v-col cols="3" v-if="item.useExcelData && item.validatingExcel" style="padding-left:10px; padding-top:13px">
                            <v-progress-circular
                              :width="3"
                              color="green"
                              indeterminate
                            ></v-progress-circular> &nbsp; Validating Configuration....
                          </v-col>
                          <v-col cols="3" v-if="item.useExcelData && item.validationData !== null" style="padding-left:10px; padding-top:13px">
                            <span v-if="item.validationResult === true" >
                              <v-icon color="success">
                                mdi-check-circle
                              </v-icon>
                              &nbsp; Validation Successful
                            </span>
                            <span v-if="!item.validationResult === true">
                              <v-icon color="red">
                                mdi-close-circle
                              </v-icon>
                              &nbsp; Validation Errors
                            </span>
                          </v-col>    
                        </v-row>    
                        <v-row dense v-if="itemContainsExcel(item) && excelConfigWarning" style="padding-top:10px;">
    
                          <v-col cols="12" style="padding-left:10px; padding-top:13px">
                            <i style="color:red"><b>No Excel Header configuration present for this client</b></i> 
                          </v-col>
                        </v-row>  
                        <v-row dense v-if="item.requiresDataCapture" style="padding-top:10px;">
                          <v-col cols="12" style="padding-left:10px; padding-top:13px">
                            <i style="color:red"><b>Data Capture Required</b></i> 
                          </v-col>
                          <v-col cols="12" v-if="itemContainsExcel(item)">
                            <v-row v-for="(dc,indx) in item.dataCaptureMap" :key="indx">
                              <v-col cols="4" style="padding-left:10px; padding-top:13px"> 
                                {{dc.method === 'form' ? dc.data_capture_field : dc.input_label}}
                              </v-col>
                              <v-col cols="4" style="padding-left:10px; padding-top:13px"> 
                                <v-select :items="excelConfigTypes" v-model="dc.type" dense></v-select>
                              </v-col>
                              <v-col cols="4" style="padding-left:10px; padding-top:13px"> 
                                <v-select v-if="dc.type === 'map'" :items="dc.col_headers" v-model="dc.value" dense></v-select>
                                <v-text-field v-if="dc.type === 'value'" dense v-model="dc.value"></v-text-field>
                              </v-col>
                            </v-row>  
                          </v-col>
                          <v-col v-else cols="12">
                            <v-row v-for="(dc,indx) in item.dataCaptureMap" :key="indx">
                              <v-col cols="4" style="padding-left:10px; padding-top:13px"> 
                                {{dc.method === 'form' ? dc.data_capture_field : dc.input_label}}
                              </v-col>
                              <v-col cols="4" style="padding-left:10px; padding-top:13px"> 
                                <v-text-field dense v-model="dc.value"></v-text-field>
                              </v-col>
                            </v-row> 
                          </v-col>
                        </v-row>   
                                           
                      </v-col>
                    </v-row> 
                  </v-col>
                </v-row>
                </v-card>
              </v-col>
              <v-col class="colFixed" cols="3">
                <v-card flat>
                  <v-row class="selectedItemZone">
                    <v-col cols="12">
                      <draggable tag="ul" :list="selected" :class="[isCreating ? 'dragZoneDisabled' : 'dragZone']"  :disabled='isCreating'
                        :group="{ name: 'row' }">
                        <li
                          v-for="item in selected"
                          :key="item.file_id"
                          class="draggableFileItem"
                        >
                          <v-icon class="handle">
                            mdi-file
                          </v-icon>

                          <span class="text">{{ item.file_name }} </span>
                        </li>
                      </draggable>   
                    </v-col>
                  </v-row>
                </v-card>
  
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  :disabled='isCreating' @click="closeHierarchyDialog">Close</v-btn>
          <v-btn color="success" :disabled='isCreating' @click="createDocs">Create</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog v-model="showCreatedDocsDialog" scrollable >
      <v-card>
        <v-card-title>Created Documents</v-card-title>
        <v-card-text style="max-height: 500px;">
            <v-row v-for="(item,index) in createdDocs" :key="index" dense>
              <v-col cols="3">{{item.doc_type}}</v-col>
              <v-col cols="6">
                <country-flag v-if="item.language_code !== null && item.language_code !== '' && item.language_code !== 'en'"  :country='getFlagForLang(item.language_code)' size='small'/>&nbsp;
                {{item.doc_name}}   
              </v-col>
              <v-col cols="2">{{item.reference}}</v-col>
              <v-col cols="1">
                <v-icon @click="openDoc(item)" >
                  mdi-magnify
                </v-icon>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeCreatedDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
    <v-dialog v-model="isCreating"
      persistent
      width="600"
    >
      <v-card
        color="success"
        dark
      >
        <v-card-text>
          Please wait
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileDeleteDialog" max-width="1200px">
      <v-card>
          <v-card-title class="justify-center">
          </v-card-title>
          <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <i style="color:blue">Deleting files will not remove linked documents</i>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <h2>Are you sure you want to delete these files?</h2>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" v-for="(item,index) in deleteList" :key="index">
              {{item.file_name}}
            </v-col>
          </v-row>          
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">Close</v-btn>
          <v-btn class="error" text @click="deleteFiles()">Delete</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-dialog v-model="linkedFilesDialog" max-width="1200px" scrollable >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text style="max-height: 500px;">
          <v-row v-for="(item,index) in viewLinkedFiles" :key="index" dense>
              <v-col cols="3">{{item.doc_type}}</v-col>
              <v-col cols="6">
                <country-flag v-if="item.language_code !== null && item.language_code !== '' && item.language_code !== 'en'"  :country='getFlagForLang(item.language_code)' size='small'/>&nbsp;
                {{item.doc_name}}
              </v-col>
              <v-col cols="2">{{item.reference}}</v-col>
              <v-col cols="1">
                <v-icon @click="openDoc(item)" >
                  mdi-magnify
                </v-icon>
              </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLinkedFilesDialog">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>   
    </v-dialog>   
    <v-dialog v-model="newPresetDialog" max-width="1200px">
      <v-card>
          <v-card-title class="justify-center">
            Add New File Parsing Preset
          </v-card-title>
          <v-card-text>
          <v-row dense>
            <v-col>
              <v-text-field dense v-model="tmpPreset.name" label="Preset Name"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-checkbox dense v-model="tmpPreset.tmpl_specific" label="Template Specific?"></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="tmpPreset.tmpl_specific" dense>
            <v-col>
              <v-select :items="tmplOptions" item-text="tmpl_name" item-value="tmpl_id" dense v-model="tmpPreset.selected_tmpl"></v-select>
            </v-col>
          </v-row>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeNewPresetDialog">Close</v-btn>
          <v-btn color="blue darken-1" text @click="addNewPreset">Add</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>         
    <v-dialog v-model="docPreviewDialog"
      fullscreen
      persistent
      no-click-animation
      transition="dialog-bottom-transition"
      scrollable 
      :close-on-content-click="false"
    >
      <v-card  class="docPreviewPanel">
          <v-card-title class="justify-center">
          <v-row dense>
            <v-col dense cols="2" style="display: inline-flex; padding-right:40px">
              <v-row dense>
                <v-col cols="8">
                  <v-select dense :items="filePresets" @change="selectPreset" label="Preset" item-text="name" v-model="selectedPreset" return-object></v-select>
                </v-col>
                <v-col cols="2">
                  <v-icon color="green" style="padding-left: 5px;" @click="savePreset">
                    mdi-content-save
                  </v-icon>
                </v-col>   
                <v-col cols="2">
                  <v-icon color="blue" style="padding-left: 5px;" @click="openNewPresetDialog">
                    mdi-plus-circle
                  </v-icon>
                </v-col>                
              </v-row>
            </v-col>
            <v-col cols="5">
              Original Text - {{previewData.file}}
            </v-col>
            <v-col cols="2" align="center">
              <span v-if="previewData.jobCount > 1">
                <v-icon v-if="currentMultiJobItem > 1" @click="changeJobId('sub')">mdi-chevron-left</v-icon>
                Jobs: {{currentMultiJobItem}} of {{previewData.jobCount}}
                <v-icon v-if="currentMultiJobItem <= previewData.jobCount" @click="changeJobId('add')">mdi-chevron-right</v-icon>
              </span>
            </v-col>
            <v-col cols="3" style="padding-left: 25px;">
              Data                   
              <v-icon color="green" style="padding-left: 5px;" @click="saveCorrections">
                mdi-content-save
              </v-icon>
            </v-col>
          </v-row>            
          </v-card-title>
          <v-card-text>

          <v-row dense>
            <v-col cols="2" class="colFixed" align="center">
              <v-row dense v-for="(item,index) in previewData.results" :key="index">
                <v-col cols="9" align="center" style="display:inline-flex">
                  <v-switch
                    v-model="item.enabled"
                    :label="item.type"
                    @change="updateItem(item)"
                  ></v-switch>    
                </v-col>  
                <v-col cols="3" align="right">     
                  <v-menu offset-y :close-on-content-click="false" v-if="item.enabled">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        v-on=on
                        style="display:inline-flex;margin-right: 10px;margin-top: 5px;"
                        v-if="item.enabled"
                      >
                      <v-icon x-small>
                        mdi-palette
                      </v-icon>
                      </v-btn>
                    </template>
                    <v-color-picker
                      :value="item.color"
                      v-model="item.color"
                      elevation="15"
                      class="mx-auto"
                    >
                    </v-color-picker>
                  </v-menu> 
                </v-col>  
                <v-col cols="12" :id="item.type" align="center"> 
                  <round-slider
                    start-angle="315"
                    end-angle="+270"
                    width="10"
                    line-cap="round"
                    radius="40" 
                    v-model="item.value"
                    :change="updateData"
                    v-if="item.enabled"
                    :rangeColor="item.color"
                    pathColor="#dbdada"
                  />  
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="7" class="colFixed" @mouseover="mouseOver" style="padding: 10px;">
              <span v-html="previewData.text"></span>
            </v-col>
            <v-col cols="3" class="colFixed data" style="padding-top: 15px; padding-left: 15px;">
              <v-row>
                <v-col cols="12" v-if="corrections.length" style="padding-top: 15px;">
                  <v-expansion-panels>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header style="padding-left:10px">
                        Manual Corrections
                        <template v-slot:actions >
                            <v-btn style="width: 25px;height: 25px;margin-right: 5px;" color="warning" fab x-small dark>{{corrections.length}}</v-btn>
                        </template>

                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-chip
                          v-for="(val, index) in corrections"
                          class="ma-1"
                          :key="`${val.type}_${index}`"
                          :color="val.color"
                          
                        >
                        <span class="wrapclass" :title="val.ignore === 1 ? 'Global Ignore -' + val.text : 'Ignore ' + val.previous_category + ' - ' + val.text">
                          {{val.text}}
                        </span>
                        </v-chip>
                      
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>      
                </v-col>
              </v-row>
              <v-row dense v-for="(item,index) in previewData.results" :key="index">
                <v-col cols="12" :id="`${item.type}_${index}`" v-if="item.enabled" style="padding-top: 15px;">
                  <v-expansion-panels>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header style="padding-left:10px">
                        {{item.type}}
                        <template v-slot:actions >
                            <v-btn style="width: 25px;height: 25px;margin-right: 5px;" :color="item.color" fab x-small dark>{{item.active.length}}</v-btn>
                        </template>

                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-chip
                          v-for="(val, index) in item.active"
                          class="ma-1"
                          :key="`${item.type}_${index}`"
                          :color="item.color"
                          @click="setNlpItem(val.item)"
                        >
                        <span class="wrapclass" :title="val.item">
                          {{val.item}}
                        </span>
                        </v-chip>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>      
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="docPreviewDialog = false; showMenu = false;">Close</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>   
    <v-dialog v-model="viewNlpItem" 
      width="1200px"   
    >
      <v-card>
        <v-card-text>
          <v-card-title>
            Document Data:
          </v-card-title>
          <v-row>
            <v-col cols="12">
              {{currentNlpItem.text}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Appears in the following categories:
            </v-col>
            <v-col cols="6">
              <v-btn color="warning" @click="removeTagFromAll(currentNlpItem)">Globally Ignore Text</v-btn>
            </v-col>
          </v-row>
          <v-row>            
            <v-col cols="12">
              <v-chip
                v-for="(item,index) in currentNlpItem.values" :key="index"
                class="ma-2"
                close
                :color="item.color"
                @click:close="removeTag(item,currentNlpItem.text, true)"
              >
                <small>{{item.type}} - {{item.value}}%</small>
              </v-chip>

            </v-col>
          </v-row>          
          <v-row>
            <v-col cols="12">
              <v-btn @click="viewNlpItem = false">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>      
    <v-dialog v-model="progressDialog" 
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      scrollable 
    >
      <v-card class="uploadContentHierarchyPanel">
          <v-card-title class="justify-center">
            Documents Creating....
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col class="colFixed" cols="9">   
                <v-card flat>
                <v-row class="hierarchyGroupRow" v-for="(item,index) in hierarchyGroups" :key="item.index">
                  <v-col cols="12">
                    <v-row style="cursor:pointer" dense class="hierarchyGroupHeader">
                      <v-col cols="12">
                        Document Creation Group {{index+1}} <span v-if="item.items.length > 0">({{item.items.length}} Document{{item.items.length === 1 ? '' : 's'}})</span>  - {{item.doc_type}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="3">
                            <v-row dense>
                              <v-col cols="12">
                                {{item.doc_type}}
                              </v-col>
                            </v-row>                      
                            <v-row dense>
                            <v-col
                              cols="12"
                              v-if="
                                item.selectedTemplate &&
                                item.selectedTemplate.lifecycles.length > 1 
                                && !item.isActionSelected
                              "
                            >
                              <v-radio-group
                                v-model="item.selectedActionId"
                                disabled
                                row
                                v-if="item.selectedTemplate.lifecycles.length > 2"
                              >
                                <v-radio
                                  v-for="(l, li) in item.selectedTemplate.lifecycles"
                                  :key="'l' + li"
                                  disabled
                                  :label="l.lifecycle_selection_text || 'Default'"
                                  :value="l.lifecycle_action_id"
                                ></v-radio>
                              </v-radio-group>
                              <v-switch
                                v-else
                                v-model="item.selectedActionNonDefault"
                                disabled
                                inset
                                :label="item.selectedTemplate.lifecycles[1].lifecycle_selection_text"
                              ></v-switch>
                            </v-col>
                            </v-row>                            
                          </v-col>
                          <v-col cols="5">
                            <v-row 
                                v-for="(h, hi) in item.hierarchies"
                                :key="'h' + hi" dense  >
                              <v-col
                                cols="12"
                                class="expand"
                              >
                                <v-row dense>
                                  <v-col cols="12">
                                    {{h.hierarchy_text}}
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>
                          <v-col cols="4">
                           <v-row v-for="item in item.items" :key="item.file_id">
                            <v-col cols="12">{{item.file_name}}</v-col>
                           </v-row>
                          </v-col>
                        </v-row>
                        <v-row style="padding-top:15px;" dense>
                          <v-col cols="3">
                            <v-icon>mdi-cog</v-icon>&nbsp; Document Creation Options:
                          </v-col>
                        </v-row>                         
                        <v-row style="padding-top:5px;" dense>
                          <v-col cols="2">
                            <v-checkbox dense disabled v-model="item.create_empty" label="Categorise Document?"></v-checkbox>
                          </v-col>
                          <v-col v-if="item.create_empty" cols="10" style="padding-top:10px">
                            <span>Please Note: Selecting this option will attempt to run document text through the Categoriser service!</span>
                          </v-col>
                        </v-row>                   
                        <v-row dense v-if="enabledTranslate && !item.create_empty" style="padding-top:5px;">
                          <v-col cols="2">
                            <v-checkbox dense disabled v-model="item.translate" label="Translate Document?"></v-checkbox>
                          </v-col>
                          <v-col cols="5">
                            <v-select dense v-model="item.target_lang" v-if="item.translate" disabled :items="translateOptions" label="Target Language">
                              <template slot="item" slot-scope="item">
                                <div>
                                  <country-flag :country='getFlagCode(item)' size='normal'/><span style="padding-left: 9px; padding-bottom: 5px; vertical-align: middle;">{{item.item.text}}</span>
                                </div>
                              </template>
                              <template v-slot:selection="item">
                                  <country-flag :country='getFlagCode(item)' size='normal'/><span style="padding-left: 9px; padding-top: 10px;">{{item.item.text}}</span>
                              </template>   
                            </v-select>
                          </v-col>   
                        </v-row>  
                        <v-row dense v-if="itemContainsExcel(item)" style="padding-top:10px;">
                          <v-col cols="2"  style="padding-left:10px; padding-top:13px">Total Rows: {{item.items[0].rowCount}}</v-col>
                          <v-col cols="1"><v-text-field dense disabled @keydown="isNumber($event)" :min="1" :max="item.items[0].rowCount" type="number" label="Start Row:" v-model="item.items[0].startRow"></v-text-field></v-col>  
                          <v-col cols="1"><v-text-field dense disabled @keydown="isNumber($event)" :min="1" :max="item.items[0].rowCount"  type="number" label="End Row:"  v-model="item.items[0].endRow"></v-text-field></v-col>  
                          <v-col cols="3" style="padding-left: 25px; padding-top: 7px;" v-if="!excelConfigWarning">
                            <v-checkbox dense disabled v-model="item.useExcelData" @click="validateExcel(item)" label="Use Excel Hierarchy Data?"></v-checkbox>
                          </v-col>  
                        </v-row>                                              
                      </v-col>
                    </v-row> 
                  </v-col>
                </v-row>
                </v-card>
              </v-col>
              <v-col class="colFixed" cols="3">
                <div style="max-height: 800px;
              height: 800px;
              overflow-y: scroll;
              background-color: rgb(60, 60, 60);
              color: white;
              font-size: 11px;
              width: 100%;
              margin-top: 12px;
              padding-left: 10px !important;
              padding-top: 10px;" >
                  Events Console:
                  <div v-for="(log,idx) in consoleLog" :key="idx">
                    {{log}}<br/>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="progressDialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>    
    </v-dialog>    
    <v-dialog v-model="excelConfigDialog" 
      fullscreen
      persistent
      scrollable
    >
      <v-card>          
        <v-card-title>
          Excel File Configuration
        </v-card-title>
        <v-card-text  v-if="excelConfiguration.display === 'config'">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12"><b>Column Mappings</b> &nbsp; <v-icon @click="addNewColumnMap" color="success">mdi-plus-circle</v-icon></v-col>
                </v-row>
                <v-row style="padding-bottom:5px">
                  <v-col cols="5"><b>Document Data Field</b></v-col>
                  <v-col cols="5"><b>Spreadsheet Column</b></v-col>
                  <v-col cols="1"><b>Split?</b></v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-row dense v-for="(c, cIndex) in excelConfiguration.config.filter(x => x.type === 'column_map')" :key="cIndex">
                  <v-col cols="5">  
                    <v-autocomplete :items="excelDataFieldTargets" item-text="label" item-value="value" v-model="c.data" dense></v-autocomplete>
                  </v-col>
                  <v-col cols="5">
                    <v-select :items="excelSampleData.headers" v-model="c.col_header" dense></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-text-field v-model="c.delimiter" dense></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="deleteConfigItem(c)"><v-icon>mdi-delete</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12"><b>Hierarchy Mappings</b></v-col>
                </v-row>

                <v-expansion-panels multiple v-for="(c, cIndex) in excelConfiguration.config.filter(x => x.type === 'hierarchy_map')" :key="cIndex">
                  <v-expansion-panel
                     v-for="(ht, hIndex) in c.ht" :key="hIndex" 
                  >
                    <v-expansion-panel-header>
                      {{ht.ht_name}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row v-for="(l, lIndex) in ht.levels" :key="lIndex">    
                          <v-col cols="2">Level {{l.level}}</v-col>
                          <v-col cols="2">
                            <v-select dense :items="excelConfigTypes" v-model="l.type"></v-select>
                          </v-col>
                          <v-col v-if="l.type==='value'" cols="5">
                            <v-text-field dense v-model="l.value"></v-text-field>
                          </v-col>
                          <v-col v-if="l.type==='map'" cols="5">
                             <v-select :items="excelSampleData.headers" v-model="l.column" dense></v-select>
                          </v-col>
                          <v-col cols="2">
                            <v-checkbox v-model="l.addProcess" label="Customise?"></v-checkbox>
                          </v-col>
                          <v-col v-if="l.addProcess" cols="3">
                            <v-select dense :items="['regex']" v-model="l.process.type"></v-select>
                          </v-col>
                          <v-col v-if="l.addProcess" cols="9">
                            <v-text-field dense v-model="l.process.value"></v-text-field>
                          </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
             
              </v-col>
            </v-row>
            <v-data-table
              v-if="excelSampleData.preview"
              :headers="excelSampleData.tableHeaders"
              :items="excelSampleData.sampleText"
              
              :hide-default-header="true"
              class="elevation-1"
            >
            <template v-slot:header="{ props: { headers } }">
              <tr style="height:40px">
                  <th
                    v-for="(header,i) in headers"
                    :key="i"
                    style="cursor:pointer"
                  >
                    {{header.text}}
                  </th>

              </tr>
            </template>
            </v-data-table>
        </v-card-text>
        <v-card-text v-if="excelConfiguration.display === 'preview'">
          {{excelSampleData.file_name}}
            <v-data-table
              :headers="excelSampleData.tableHeaders"
              :items="excelSampleData.sampleText"
              
              :hide-default-header="true"
              class="elevation-1"
            >
            <template v-slot:header="{ props: { headers } }">
              <tr style="height:40px">
                  <th
                    v-for="(header,i) in headers"
                    :key="i"
                    @click="editHeaderSettings(header.text)"
                    style="cursor:pointer"
                  >
                    {{header.text}}
                  </th>

              </tr>
            </template>
            </v-data-table>
        </v-card-text>
        <v-card-text v-if="excelConfiguration.display === 'sample'">
          <v-row>
            <v-col cols="12">
              {{excelConfiguration.sampleData.length}} Sample Documents
            </v-col>
          </v-row>
          <v-row v-for="(f,index) in excelConfiguration.sampleData" :key="index">
            <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                  {{f.file_name}}
                  <template v-slot:actions >
                      <v-btn style="width: 25px;height: 25px;margin-right: 5px;" :color="f.errors? 'warning' : 'success'" fab x-small dark>
                        <v-icon v-if="f.errors">
                          mdi-exclamation
                        </v-icon>
                        <v-icon v-else>
                          mdi-check-circle
                        </v-icon>
                      </v-btn>
                  </template>                  
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels multiple>
                    <v-expansion-panel
                      v-for="(s, hIndex) in f.samples" :key="hIndex" 
                    >
                      <v-expansion-panel-header disable-icon-rotate>
                        Sample Job {{hIndex+1}}
                        <template v-slot:actions >
                            <v-btn style="width: 25px;height: 25px;margin-right: 5px;" :color="s.errors? 'warning' : 'success'" fab x-small dark>
                              <v-icon v-if="s.errors">
                                mdi-exclamation
                              </v-icon>
                              <v-icon v-else>
                                mdi-check-circle
                              </v-icon>
                            </v-btn>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row v-for="(d,dIdx) in s.data" :key="dIdx">
                          <v-col cols="3"
                          :style="{ color: d.error ? 'red' : '' }"
                          >{{d.text}}</v-col>
                          <v-col cols="9" :style="d.error ? 'red' : ''">{{d.value}}</v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels> 
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels> 
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions >
          <v-btn color="primary" @click="doValidate">Validate</v-btn>
          <v-btn color="success" v-if="excelConfiguration.display !== 'preview'" @click="showPreview">Preview</v-btn>
          <v-btn color="success" v-if="excelConfiguration.display !== 'sample'" @click="generateSample">Sample</v-btn>
          <v-btn color="success" v-if="excelConfiguration.display !== 'config'" @click="showConfig">Back to Config</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="white" @click="closeExcelConfig">Close</v-btn>
          <v-btn color="success" :disabled="!excelConfiguration.validated" @click="doConfigSave">Save</v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>     
 
    <v-dialog v-model="newHierarchyDialog" max-width="1200px">
      <v-card>
          <v-card-title class="justify-center">
            Add New {{newHierarchyItem.ht_name}} Hierarchy
          </v-card-title>
          <v-card-text>
            <v-row dense v-if="newHierarchyItem.use_h1">
              <v-col dense cols="4">
                Level 1:
              </v-col>
              <v-col dense cols="8">
                <v-text-field dense v-model="newHierarchyItem.level1"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="newHierarchyItem.use_h2">
              <v-col dense cols="4">
                Level 2:
              </v-col>
              <v-col dense cols="8">
                <v-text-field dense v-model="newHierarchyItem.level2"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="newHierarchyItem.use_h3">
              <v-col dense cols="4">
                Level 3:
              </v-col>
              <v-col dense cols="8">
                <v-text-field dense v-model="newHierarchyItem.level3"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="newHierarchyItem.use_h4">
              <v-col dense cols="4">
                Level 4:
              </v-col>
              <v-col dense cols="8">
                <v-text-field dense v-model="newHierarchyItem.level4"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="newHierarchyItem.use_h5">
              <v-col dense cols="4">
                Level 5:
              </v-col>
              <v-col dense cols="8">
                <v-text-field dense v-model="newHierarchyItem.level5"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="newHierarchyItem.use_h6">
              <v-col dense cols="4">
                Level 6:
              </v-col>
              <v-col dense cols="8">
                <v-text-field dense v-model="newHierarchyItem.level6"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeNewHierarchyDialog">Close</v-btn>
          <v-btn color="blue darken-1" text @click="addNewHierarchyToGroup">Add</v-btn>
          <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </v-dialog>  
    <Loading :isVisible="isLoading" />
      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        :close-on-content-click="false"
        absolute
        offset-y
        dense
      >
      <v-card style="min-width:400px" dense>       
        <v-chip
           v-for="(item,index) in currentNlpItem.values" :key="index"
          class="ma-2"
          close
          :color="item.color"
          @click:close="removeTag(item,currentNlpItem.text, true)"
        >
          <small>{{item.type}} - {{item.value}}%</small>
        </v-chip>
        <v-icon @click="setNlpItem(currentTarget)" >
          mdi-pencil
        </v-icon>
      </v-card>
      </v-menu>  
    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      <span v-html="errorText"></span>

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showErrorSnack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>           
  </v-card>
  
</template>

<script>

import axios from "axios";
import FileUploader from '../components/cFileUpload.vue';
import { mapState } from "vuex";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import draggable from "vuedraggable";
import RoundSlider from 'vue-round-slider'
import nlpUtils from "@/common/nlpUtils.js";
import utils from "@/common/utils.js";
import CountryFlag from 'vue-country-flag';
//import UploadFiles from '../components/cUploadFiles.vue'
//import UploadData from '../components/cUploadData.vue'


export default {
  name: 'uploadContent',
  data: function() {
    return {
        isCreating: false,
        page: 1,
        groupCounter: 0,
        pageCount: 0,
        itemsPerPage: 10,
        filteredFileItems: [],
        selected: [],
        viewMode: 'List',
        search: '',
        displayMode: 'small',
        multiUpload: "true",
        headers: [
          { text: 'File Name', value: 'file_name' },
          { text: 'File Type', value: 'file_extension' },
          { text: 'Linked Docs', value: 'docLinks' },
          { text: 'Uploaded By', value: 'created_by_user' },
          { text: 'Uploaded Time', value: 'created_at', align: 'start', },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        hierarchiesDialog: false,
        docTypes: [],
        docType: "",
        fileUploadFormData: {
          parseDoc: false
        },
        docCreate_empty: false,
        disableFileUpload: false,
        hierarchyGroups: [],
        enabled: true,
        errorText: "",
        showErrorSnack:false,
        snackColor: "error",
        snackTimeout: 3000,      
        hierarchyData: [],
        createdDocs: [],
        showCreatedDocsDialog: false,
        fileDeleteDialog: false,
        docPreviewDialog: false,
        previewData: {},
        documentData: {},
        filePresets: [],
        selectedPreset: {},
        showMenu: false,
        x: 0,
        y: 0,
        tempNlpText: "",
        nlpOptions: [],
        corrections: [],
        currentNlpTags: [],
        currentTarget: "",
        viewNlpItem: false,
        currentNlpItem:{},
        currentFileId:0,
        currentMultiJobItem: 1,
        linkedFilesDialog: false,
        viewLinkedFiles: [],
        deleteList: [],
        enabledTranslate: false,
        excelConfigWarning: false,
        newPresetDialog: false,
        tmpPreset: {
          file_preset_id:0,
          name: "",
          global: false,
          active:true,
          tmpl_specific: false,
          selected_tmpl: null,
          config: []
        },
        excelConfigDialog:false,
        excelSampleData: {
          headers:[],
          sampleText: [],
          tableHeaders: [],
          tableData:[]
        },
        excelConfiguration: {
          "display":"config",
          "preview":false,
          "validated":false,
          "sample":false,
          "config": [],
          "sampleData": [],
          "sampleIdx": 0
        },
        excelConfigTypes: [
          {"text":"Map", "value":"map"},
          {"text":"Text", "value":"value"},
        ],
        excelDataFieldTargets: [],
        useGateway: false,
        console: false,
        consoleLog: [],
        progressDialog: false,
        awaitingResponse: false,
        newHierarchyDialog: false,
        newHierarchyItem: {
          "ht_id":0,
          "group_id":0,
          "ht_name":"",
          "level1":"",
          "level2":"",
          "level3":"",
          "level4":"",
          "level5":"",
          "level6":"",
        }
    }
  },
  watch: {
    hierarchiesLoading(val) {
      if (val === false) {
        this.setData();
      }
    },
   $route (){
      if(this.useGateway){
        this.unregisterGatewayListeners();
      }
    }    
  },
  created() {
    this.getFilePresets();
    this.fetchFileData();
    this.setData();
  },
  beforeDestroy(){
    if(this.useGateway){
      this.unregisterGatewayListeners();
    }
  },
  mounted() {
    this.connectToGateway();
  },
  components: {
    FileUploader, HierarchyTreePicker,
    draggable, RoundSlider, CountryFlag
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchies: (state) => state.hierarchies.hierarchies,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
    }),    
    docCreationCount(){
      return this.hierarchyGroups.filter(x => x.items.length > 0).length;
    },
    translateOptions: function () {
      return utils.translateOptions;
    },    
    anyExcelUploaded(){
      return this.filteredFileItems.some(x => x.file_extension === "xlsx" || x.file_extension === "xls");
    },
  },  
  methods:{
    deleteCustomHierarchyData(h){
      h.customHierarchy = false;
      h.customData = null;
      this.hierarchyGroups = JSON.parse(JSON.stringify(this.hierarchyGroups));
    },
    resetNewHierarchyItem(){
      this.newHierarchyItem = {
          "ht_id":0,
          "customHierarchy":true,
          "group_id":0,
          "ht_name":"",
          "level1":"",
          "level2":"",
          "level3":"",
          "level4":"",
          "level5":"",
          "level6":""
        };
    },
    addNewHierarchyToGroup(){
      let source = this.hierarchyGroups[this.newHierarchyItem.group_id];
      if(source){
        let newHr = [];
        [1, 2, 3, 4, 5, 6].forEach((n) => {
          if(this.newHierarchyItem["use_h" + n]){
            newHr.push(this.newHierarchyItem["level" + n]);
          }
        });

        let newHrObj = {
          "hr_id": 0,
          "ht_id": this.newHierarchyItem.ht_id,
          "path": newHr
        };

        let hrItem = source.hierarchies.find(x => x.ht_id === this.newHierarchyItem.ht_id);
        if(hrItem === undefined){
          source.hierarchies.push(newHrObj);
        } else {
          source.hierarchies.splice(source.hierarchies.indexOf(hrItem),1);
          source.hierarchies.push(newHrObj);
        }

        let hrDataItem = source.hierarchyData.find(x => x.ht_id === this.newHierarchyItem.ht_id);
        hrDataItem.customHierarchy = true;
        hrDataItem.customData = newHr;

        this.closeNewHierarchyDialog();
      }

    },
    closeNewHierarchyDialog(){
      this.newHierarchyDialog = false;
      this.resetNewHierarchyItem();
    },
    addNewHierarchy(group, h){
      this.newHierarchyDialog = true;
      this.newHierarchyItem = {...h,
          "group_id": group,
          "customHierarchy":true,
          "ht_name":"",
          "level1":"",
          "level2":"",
          "level3":"",
          "level4":"",
          "level5":"",
          "level6":""
      };
    },
    connectToGateway(){
      this.useGateway = this.$loginState.user.settings.some(s => s.setting === 'use_event_gateway' && s.value === 'true');
      if(this.useGateway){
        if(!this.$eventGateway.connectionState){
          this.consoleLog.push(`Not connected...Connecting!`);
          this.$eventGateway.connect(this.$loginState.user.name);
        } else {
          this.consoleLog.push(`Already Connected`);
        }
        this.registerGatewayListeners();
        this.console = true;
        this.$eventGateway.send("join",{ "roomName":"BulkDocCreationRoom" });
      }
    },
    unregisterGatewayListeners(){
      //this.$eventGateway.$off("connected");
      this.$eventGateway.$off("document-bulk-create-complete");
      this.$eventGateway.$off("document-bulk-create-update");
      this.$eventGateway.$off("roomCreated");
      this.$eventGateway.$off("roomUpdate");
      this.$eventGateway.$off("newUser");
      this.$eventGateway.disconnect();
    },
    registerGatewayListeners(){
      //this.$eventGateway.$on("connected", this.gatewayConnection);
      //this.$eventGateway.$on("document-translate-complete", this.completeEvent);
      //this.$eventGateway.$on("document-translate-update", this.docStatusUpdate);
      this.$eventGateway.$on("document-bulk-create-complete", this.completeEvent);
      this.$eventGateway.$on("document-bulk-create-update", this.docStatusUpdate);
      this.$eventGateway.$on("roomCreated", this.roomCreated);
      this.$eventGateway.$on("roomUpdate", this.roomUpdate);
      this.$eventGateway.$on("newUser", this.newUser);
      
    },
    docStatusUpdate(data){
      this.consoleLog.push(data.message);
      console.log(data.message);
    },
    roomUpdate(data){
      this.consoleLog.push(`User List:`);
      data.users.forEach(u => {
        this.consoleLog.push(u);
      })
    },
    newUser(data){
      this.consoleLog.push(`New User Connected: ${data}`);
    },
    roomCreated(data){
      console.log("room created - " + data);
      this.consoleLog.push(`Joined ${data.room}`);
      this.consoleLog.push(`User List:`);
      data.users.forEach(u => {
        this.consoleLog.push(u);
      })
      //this.triggerNotification("Event Gateway Connected", "success");
    },
    gatewayConnection(){
      this.consoleLog.push("Event Gateway Connected");
      //this.triggerNotification("Event Gateway Connected", "success");
    },
    completeEvent(data){
      //this.consoleLog.push(data);
      this.triggerNotification(`${data.details.length} Documents Created`,"success")
      this.showCreateDocs(data.details);
      this.fetchFileData();
    },
    validateExcel(item){
      if(item.validationResult){
        return;
      }
      this.$nextTick(() => {
        item.validatingExcel = true;  
      });      
      if(this.excelConfiguration.config.length === 0){
        this.setupExcelConfig();
      }
      if(item.useExcelData && item.validationData === null){

        let excel = item.items.find(x => x.file_extension === "xlsx" ||  x.file_extension === "xls");
        let configData = this.excelConfiguration.sampleData.find(x => x.file_name === excel.file_name);
        if(configData){
          item.validationData = configData;
          let obj = this.validateIndividualDocConfig(configData);
          if(obj.length === 0){
            this.item.validationResult = true;
          } else {
            this.item.validationResult = false;
          }
          item.validatingExcel = false;          
        } else {
          let allExcel = this.filteredFileItems.filter(x => x.file_extension === "xlsx" || x.file_extension === "xls");
          axios.get("file/getSpreadsheetInfo",  { params: { ids:  allExcel.map(x => { return x.file_id }), sampleCount: 3, config: JSON.stringify(this.excelConfiguration.config)}})
          .then((resp) => {
            
            let configData = resp.data.find(x => x.file_name === excel.file_name);
            
            if(configData){
              item.validationData = configData;
              let obj = this.validateIndividualDocConfig(configData);
              this.$nextTick(() => {
                item.validationResult = !obj.errors;
                item.validatingExcel = false;    
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
        }
      }
      item.validatingExcel = false;
    },
    validateIndividualDocConfig(item){
        let obj = {
            "file_name": item.file_name,
            "errors": false,
            "samples": [],
        };       
        item.sampleText.forEach((d,dIdx) => {
          let sample = {
            "index": dIdx,
            "errors": false,
            "data":[]
          };

          this.excelConfiguration.config.forEach(c => {
            if(c.type==="column_map"){
                sample.data.push({
                  "text": c.data,
                  "value": d[c.col_header],
                  "delimiter": c.delimiter,
                  "tmpl_part_id": c.tmpl_part_id
                });
                //sample[c.data] = d[c.col_header];
            } else if (c.type==="hierarchy_map"){
              c.ht.forEach(ht => {
                let hrVals = ht.levels.map(l => {
                  let value = l.type === "map" ? d[l.column] : l.value;
                  if(l.process){

                    if(l.process.type === "regex" && l.process.value !== ''){
                        let reg = value.match(new RegExp(l.process.value, "gi"))
                        value = reg[0].trim();
                    }
                  }
                  return value;
                });
                let filtered = hrVals.filter(x => x).join(" > ");
                sample.data.push({
                  "text": ht.ht_name + " Hierarchy",
                  "value": filtered,
                  "error": filtered === ''
                });
              });
            }
          })

          let errors = sample.data.some(x => x.value === '');
          sample.errors = errors;
          obj.samples.push(sample);
        }) 

        let errors = obj.samples.some(x => x.errors);
        obj.errors = errors;

        return obj;
    },
    formatSampleData(){
      let newData = [];
      this.excelConfiguration.sampleData.forEach((s,sidx) => {
        /*let obj = {
            "index": sidx,
            "file_name": s.file_name,
            "errors": false,
            "samples": [],
        };       
        s.sampleText.forEach((d,dIdx) => {
          let sample = {
            "index": dIdx,
            "errors": false,
            "data":[]
          };

          this.excelConfiguration.config.forEach(c => {
            if(c.type==="column_map"){
                sample.data.push({
                  "text": c.data,
                  "value": d[c.col_header]
                });
                //sample[c.data] = d[c.col_header];
            } else if (c.type==="hierarchy_map"){
              c.ht.forEach(ht => {
                let hrVals = ht.levels.map(l => {
                  let value = l.type === "map" ? d[l.column] : l.value;
                  if(l.process){

                    if(l.process.type === "regex"){
                        let reg = value.match(new RegExp(l.process.value, "gi"))
                        value = reg[0].trim();
                    }
                  }
                  return value;
                });
                let filtered = hrVals.filter(x => x !== undefined).join(" > ");
                sample.data.push({
                  "text": ht.ht_name + " Hierarchy",
                  "value": filtered,
                  "error": filtered === ''
                });
              });
            }
          })

          let errors = sample.data.some(x => x.value === '');
          sample.errors = errors;
          obj.samples.push(sample);
        }) 

        let errors = obj.samples.some(x => x.errors);
        obj.errors = errors;*/
        let obj = this.validateIndividualDocConfig(s);
        obj["index"] = sidx;
        newData.push(obj);
      });

      this.excelConfiguration.sampleData = newData;
    },
    generateSample(){
      this.isCreating = true;
      let allExcel = this.filteredFileItems.filter(x => x.file_extension === "xlsx" || x.file_extension === "xls");
      axios.get("file/getSpreadsheetInfo",  { params: { ids:  allExcel.map(x => { return x.file_id }), sampleCount: 3, config: JSON.stringify(this.excelConfiguration.config)}})
      .then(resp => {
        //this.excelSampleData = resp.data[0];
        //this.excelConfigDialog = true;
        this.isCreating = false;
        this.excelConfiguration.sampleData = resp.data;
        this.excelConfiguration.sampleIdx = 0;
        this.formatSampleData();
        this.excelConfiguration.display = "sample";
      })
      .catch(err => {
        console.log(err);
      });
    },
    deleteConfigItem(c) {
      const idx = this.excelConfiguration.config.indexOf(c);
      if (idx >= 0) {
        this.excelConfiguration.config.splice(idx, 1);
      }
    },
    doConfigSave(){

      this.isCreating = true;

      let config = this.excelConfiguration.config.map(c => {
        if(c.type === "column_map"){
          let field = this.excelDataFieldTargets.find(x => x.value === c.data);
          return {
            ...c,
            tmpl_part: field.text,
            tmpl_part_id: field.tmpl_part_id
          }
        } else {
          return c;
        }
      })

      let data = {
        "settingValue":"xlsx_format",
        "value": JSON.stringify(config),
      }

      axios.post("file/saveExcelConfig", data)
      .then(() => {
        this.isCreating = false;
        this.triggerNotification("Config saved!","success");
      })
      .catch(err => {
        this.triggerNotification(err,"error");
        console.log(err);
      });

    },
    showConfig(){
      this.$nextTick(() => {
        this.excelConfiguration.preview = false;
        this.excelConfiguration.display = "config";
      });
    },
    showPreview(){
      this.$nextTick(() => {
        this.excelConfiguration.preview = true;
        this.excelConfiguration.display = "preview";
      });      
    },
    validate(){
      let errors = [];
      let jobContent = this.excelConfiguration.config.some(c => c.type === 'column_map' && c.data === 'job');

      if(!jobContent){
        errors.push("No Job Data Column Mapping");
      }

      let hierarchyConfig = this.excelConfiguration.config.find(c => c.type === 'hierarchy_map');
      if(hierarchyConfig){
        hierarchyConfig.ht.forEach(ht => {
          let target = this.hierarchyTypes.find(x => x.ht_id === ht.ht_id);
          if(target){
            [1, 2, 3, 4, 5, 6].forEach((n) => {
              if(target["use_h" + n]){
                let htConfig = ht.levels.find(x => x.level === n);
                if(htConfig){
                  let value = htConfig.type === 'value' ?  htConfig.value : htConfig.column;
                  if(!htConfig.addProcess){
                    htConfig.process = {
                      "type":"",
                      "value":""
                    }
                  }
                  if(!value){
                    errors.push(`No Value/Column Map for Level${n} (ht_id: ${ht.ht_id})`);
                  }
                } else {
                  errors.push(`No Level Config for Level${n} (ht_id: ${ht.ht_id})`);
                }
              }
            });
          } else {
            errors.push(`Mismatched HT_ID: ${ht.ht_id}`);
          }
        })
      } else {
        errors.push("No Hierarchy Config Mapping");
      }

      return errors;
    },
    doValidate(){
      let errors = this.validate();
      if(errors.length === 0){
        this.excelConfiguration.validated = true;
        this.triggerNotification("Validation successfull!","success");
      } else {
        this.excelConfiguration.validated = false;
        this.triggerNotification("Validation errors!<br/>"+errors.join("<br/>"),"error");
      }
    },
    addNewColumnMap(){
      this.excelConfiguration.config.push({
        "type":"column_map",
        "col_header":null,
        "data":null
      })
    },
    editHeaderSettings(text){
      console.log(text);
    },
    setExcelPreview(){
      this.excelSampleData.tableHeaders = [];
      this.excelSampleData.tableData = [];
      this.excelSampleData.headers.forEach(h => {
        this.excelSampleData.tableHeaders.push({
          text: h.text, value: h.value, sortable: false 
        })
      });
    },
    setupExcelConfig(){

      let excelConfig = this.$loginState.user.settings.find(x => x.setting === "xlsx_format");
      if(excelConfig){
        this.excelConfiguration.config = JSON.parse(excelConfig.value);
      } else {
        this.excelConfiguration.config = [];

        let hrConfig = {
          "type":"hierarchy_map",
          "ht": []
        }
        this.hierarchyTypes.forEach((ht) => {
          let newConfig = {
            "ht_id":ht.ht_id,
            "ht_name":ht.label,
            "levels":[]
          };

          [1, 2, 3, 4, 5, 6].forEach((n) => {
            if(ht["use_h" + n]){
              newConfig.levels.push({
                "level": n,
                "type":null,
                "column":null,
                "addProcess": false,
                "value":null,
                "process": {
                  "type":"",
                  "value":""
                }
              })
            }
          });

          hrConfig.ht.push(newConfig);
        });

        this.excelConfiguration.config.push(hrConfig);
      }

    },
    openExcelConfig(){

      this.isCreating = true;

      this.excelConfiguration.validated = false;
      this.excelConfiguration.preview = false;
      this.excelConfiguration.sample = false;
      this.excelConfiguration.display = "config";

      let allExcel = this.filteredFileItems.filter(x => x.file_extension === "xlsx" || x.file_extension === "xls");

      this.setupExcelConfig();

      //this.isCreating = false;
      //this.excelConfigDialog = true;

      axios.get("file/getSpreadsheetInfo",  { params: { ids:  allExcel.map(x => { return x.file_id }), sampleCount: 1 }})
      .then(resp => {
        this.excelSampleData = resp.data[resp.data.length-1];
        this.excelConfigDialog = true;
        this.isCreating = false;
        this.setExcelPreview(resp.data.length-1);
      })
      .catch(err => {
        console.log(err);
      });


    },
    closeExcelConfig(){
      this.excelConfigDialog = false;
    },
    openNewPresetDialog(){
      this.tmplOptions = [];
      this.tmplOptions = this.$loginState.user.createDocumentOptions.filter(x => x.tmpl_name !== 'External File').map(x => {
        return {
          tmpl_id: x.tmpl_id,
          tmpl_name: x.tmpl_name
        }
      });

      this.tmpPreset = {
        file_preset_id:0,
        name: "",
        global: false,
        active: true,
        tmpl_specific: false,
        selected_tmpl: null,
        config: []
      };
      this.newPresetDialog = true;
    },
    closeNewPresetDialog(){
      this.newPresetDialog = false;
    },
    addNewPreset(){
      if(!this.tmpPreset.tmpl_specific){
        this.tmpPreset.selected_tmpl = null;
      }
      this.filePresets.push(this.tmpPreset);
      this.selectPreset(this.tmpPreset);
      this.saveNewPreset();
    },
    getFlagForLang(code){
      if (!code || code === "en") {
        return "";
      } else {
        let option = this.translateOptions.find((x) => x.value === code);
        return option ? option.flagCode : "";
      }
    },
    getFlagCode(item){
      return item.item.flagCode;
    },    
    closeLinkedFilesDialog(){
      this.linkedFilesDialog = false;
    },
    openLinkedFilesDialog(id){
      let item = this.filteredFileItems.find(x => x.file_id === id);
      this.viewLinkedFiles= item.docLinks;
      this.linkedFilesDialog = true;
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    itemContainsExcel(item){
      if(!item){
        return false;
      }
      if(item.items.length === 0){
        return false;
      }

      let excel = item.items.some(x => x.file_extension === "xlsx" ||  x.file_extension === "xls");
      if(excel){
        if(!this.$loginState.user.settings.some((x) => x.setting === "xlsx_format")){
          this.excelConfigWarning = true;
        }
      }

      return excel;
    },    
    saveCorrections(){
      this.isCreating = true;
      axios.post('file/saveCorrections', this.corrections)
      .then((resp) => {
          if(resp.status === 200 || resp.status === 201){
            this.triggerNotification("Corrections Saved", "success");
            this.isCreating = false;
            this.getFilePresets();
          } else {
            this.triggerNotification(`Error - ${resp.statusText}`,"error")
            this.isCreating = false;
          }          
      })
      .catch(err => {
          this.triggerNotification(`Error - ${err}`,"error")
          this.isCreating = false;
      });
    },
    removeTagFromAll(item){
      let newCorrection = {
        "file_nlp_correction_id":0,
        "end_char": 0,
        "file_id": null,
        "ignore": 1,
        "length": 0,
        "new_category": null,
        "new_weighting": 0,
        "previous_category": null,
        "previous_weighting": 0,
        "start_char": 0,
        "text": item.text,
        "color": "warning"
      };

      this.corrections.push(newCorrection);

      item.values.forEach(v => {
        setTimeout(() => {
          this.removeTag(v,item.text, false);
        }, 300);
      })


      this.triggerNotification("Global Correction Added", "success");
      this.markUpContent();
      this.showMenu = false;
      this.viewNlpItem = false;
    },
    setNlpItem(item){
      let tags = [];
      this.previewData.results.forEach(r => {
        let activeItems = r.active.map(x => {return x.item.toLowerCase() });
        if(activeItems.length){
          let contains = activeItems.indexOf(item.toLowerCase()) > -1;
          if(contains){
            tags.push({
              "type": r.type,
              "color": r.color,
              "value": r.value
            });
          }
        }
      })
      this.currentNlpItem = {
        "text": item,
        "values": tags
      };      

      this.$nextTick(() => {
        this.showMenu = false;
        this.viewNlpItem = true;
      });
    },
    cleanText(text){
      return text.toString().trim().replace("  "," ").toLowerCase();
    },
    removeFromResults(type, text){
      let root = this.previewData.results.find(x => x.type === type);
      if(root){
        let value = root.active.find(x => this.cleanText(x.item) === this.cleanText(text));
        if(value){
          let idx = root.active.indexOf(value);
          if(idx > -1){
            root.active.splice(idx, 1);
          }
          idx = root.val.indexOf(value);
          if(idx > -1){
            root.val.splice(idx, 1);
          }
        }
      }
    },
    removeFromAll(type, text){
      this.removeFromResults(type,text);
    },
    removeTag(item, text, logCorrection){
      let newCorrection = {
        "end_char": 0,
        "file_id": null,
        "ignore": 0,
        "length": 0,
        "new_category": null,
        "new_weighting": 0,
        "previous_category": item.type,
        "previous_weighting": item.value,
        "start_char": 0,
        "text": text,
        "color": item.color
      };

      this.removeFromAll(item.type, text);
      let idx = this.currentNlpItem.values.indexOf(item);
      this.currentNlpItem.values.splice(idx,1);
      if(logCorrection){
        this.corrections.push(newCorrection);
      }
      this.markUpContent();
    },
    async initNlpOptions() {
      this.nlpOptions - [];
      let nlpOptions = nlpUtils.getNlpActions(this.$loginState.user.settings);
      this.nlpOptions = [...new Set(nlpOptions)];
    }, 
    closeMenu() {
      this.showMenu = false;
    },    
    updateValue(item,dir){
      dir === "up" ? item.value = item.value + 1 : item.value = item.value - 1;
      this.updateItem(item); 
    },
    updateItem(item){
      let configItem =  this.selectedPreset.config.find(x => x.type === item.type);
      if(configItem){
        configItem.color = item.color;
        configItem.value = item.value;
        configItem.enabled = item.enabled;
      } else {
          item.val.forEach(i => {
            i.active = Math.round(i.value*100) >= item.value;
            if(i.active){
              item.active.push(i);
            }
          })
        let itemColour = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
        let newItem = {
          "color": itemColour,
          "value": item.value,
          "enabled": item.enabled,
          "val": item.val,
          "active": item.active,
          "type":item.type
        };
        item.color = itemColour;
        this.$nextTick(() => {
          this.selectedPreset.config.push(newItem);
          this.previewData.results = JSON.parse(JSON.stringify(this.previewData.results));
        });

      }
    },
    saveNewPreset(){
      this.isCreating = true;

      this.selectedPreset.config.forEach(c => {
        let item = this.previewData.results.find(x => x.type === c.type);
        c.color = item.color;
        c.value = item.value;
        c.enabled = item.enabled;
      })

      let data = {
        "file_preset_id": 0,
        "name": this.selectedPreset.name,
        "global": this.selectedPreset.global,
        "active": this.selectedPreset.active,
        "tmpl_id": this.selectedPreset.selected_tmpl,
        "config":this.selectedPreset.config.length > 0 ? this.selectedPreset.config.map(c => {
            return {
              "color":c.color,
              "enabled":c.enabled,
              "type":c.type,
              "value":c.value
            }
        }) : []
      }

      axios.post('file/filePresets', data)
      .then((resp) => {
          if(resp.status === 200 || resp.status === 201){
            this.triggerNotification(`File Preset Saved Successfully`,"success")
            this.getFilePresets(this.tmpPreset.name);
            this.closeNewPresetDialog();
            this.isCreating = false;
          } else {
            this.triggerNotification(`Error - ${resp.statusText}`,"error")
            this.isCreating = false;
          }        
      })
      .catch(err => {
          this.triggerNotification(`Error - ${err}`,"error")
          this.isCreating = false;
      });
    },
    savePreset(){

      this.isCreating = true;

      this.selectedPreset.config.forEach(c => {
        let item = this.previewData.results.find(x => x.type === c.type);
        c.color = item.color;
        c.value = item.value;
        c.enabled = item.enabled;
      })

      let data = {
        "file_preset_id": this.selectedPreset.file_preset_id,
        "config":this.selectedPreset.config.length > 0 ? this.selectedPreset.config.map(c => {
            return {
              "color":c.color,
              "enabled":c.enabled,
              "type":c.type,
              "value":c.value
            }
        }) : []
      }

      axios.post('file/filePresets', data)
      .then((resp) => {
          if(resp.status === 200 || resp.status === 201){
            this.triggerNotification(`File Preset Saved Successfully`,"success")
            this.isCreating = false;
          } else {
            this.triggerNotification(`Error - ${resp.statusText}`,"error")
            this.isCreating = false;
          }        
      })
      .catch(err => {
          this.triggerNotification(`Error - ${err}`,"error")
          this.isCreating = false;
      });
    },
    loadData(data, name){
      this.corrections = data.corrections;
      this.filePresets = data.presets;
      this.selectedPreset = this.filePresets[0];
      if(name){
        this.selectedPreset = this.filePresets.find(x => x.name === name);
      }
      this.corrections.forEach(c => {
        if(c.ignore === 1){
          c.color = "warning";
        } else {
          let item = data.presets[0].config.find(x => x.type === c.previous_category);
          if(item){
            c.color = item.color;
          }
        }

      })
    },
    selectPreset(preset){
      this.selectedPreset = preset;
      this.loadPreviewConfig(this.previewData);
    },
    getFilePresets(targetName){
      axios.get('file/filePresets')
      .then(resp => {
        if(resp.status === 200){
          this.loadData(resp.data, targetName);
        } else {
          this.triggerNotification(`Error - ${resp.statusText}`,"error")
          this.isCreating = false;
        }
      })
      .catch(err => {
          this.triggerNotification(`Error - ${err}`,"error")
          this.isCreating = false;
      });
    },
    updateData(e){
      this.isCreating = true;
      let source = this.previewData.results.find(x => x.type === e.control[0].parentNode.id);
      if(source){
        source.value = e.value;
        source.active = [];
        source.val.forEach(i => {
          i.active = Math.round(i.value*100) >= e.value;
          if(i.active){
            source.active.push(i);
          }          
        })
      }
      this.markUpContent();
    },
    markUpContent(){
      this.previewData.text = this.previewData.text.replace(/<mark class='nlpMarkup'>/g,"").replace(/<\/mark>/g,"");
      let allContent = this.previewData.text;
      this.previewData.results.forEach(r => {
        r.active.forEach(v => {
          try {
              const regex = /[-[\]{}()*+?.,\\^$|#]/gm;
              let str = v.item;
              let subst = `\\$&`;
              let result1 = str.replace("&","&amp;").replace(regex, subst);

              let reg =  new RegExp(result1, 'gim');
              let result = allContent.match(reg);
              if (result){
                allContent = allContent.replace(result[0], `<mark class='nlpMarkup'>${result[0]}</mark>`)
              }
          } catch (e) {
            //console.log(e);
          }
        })
      })
      this.previewData.text = allContent;
      this.isCreating = false;
    },
    mouseOver(e){
      var target = e.target || e.srcElement;
      if(target.classList.contains("nlpMarkup")){

        e.preventDefault();
        this.showMenu = true;   
        if(target.innerText !== this.currentTarget) {
          this.x = e.clientX;
          this.y = e.clientY;       
          this.currentTarget = target.innerText;
          this.setContentLabels(target.innerText);
        }
      } else {
        this.showMenu = false;
      }
    },
    setContentLabels(text){
      let tags = [];
      this.previewData.results.forEach(r => {
        let activeItems = r.active.map(x => {return this.cleanText(x.item) });
        if(activeItems.length){
          let contains = activeItems.indexOf(this.cleanText(text)) > -1;
          if(contains){
            tags.push({
              "type": r.type,
              "color": r.color,
              "value": r.value
            });
          }
        }
      })
      this.currentNlpItem = {
        "text": this.cleanText(text),
        "values": tags
      }; 
    },
    loadPreviewConfig(data){
      data.results.forEach(d => {
        let preset = this.selectedPreset.config.find(x => x.type === d.type);
        if(preset){
          d.enabled = preset.enabled;
          d.value = preset.value;
          d.active = [];
          d.color = preset.color;
          d.val.forEach(i => {
            i.active = Math.round(i.value*100) >= preset.value;
            if(i.active){
              d.active.push(i);
            }
          })
        } else {
          d.enabled = false;
          d.value = 0;
          d.active = [];
          d.val.forEach(i => {
            i.active = false;
          })
        }
      });
      this.previewData = data;
      this.previewData.results = data.results.sort(( (a,b) => Number(b.enabled) - Number(a.enabled)));
      this.isCreating = false;
      
      this.markUpContent();
      this.docPreviewDialog = true;
    },
    changeJobId(dir){

      this.currentMultiJobItem = dir === 'add' ? this.currentMultiJobItem + 1 : this.currentMultiJobItem - 1;

      let data = {
        "file_id": this.currentFileId,
        "current": this.currentMultiJobItem
      }
      this.isCreating = true;
      axios.post('document/getDocNlpPreview', data)
      .then(resp => {
        if(resp.data.Status === "OK"){
          this.loadPreviewConfig(resp.data.Data[0]);
        } else {
          this.triggerNotification(`Error - ${resp.data.Message}`,"error")
          this.isCreating = false;
        }
      })
      .catch(err => {
          this.triggerNotification(`Error - ${err}`,"error")
          this.isCreating = false;
      });
    },
    preview(id){
      this.currentFileId = id;
      this.currentMultiJobItem = 1;
      let data = {
        "file_id": this.currentFileId,
        "current": this.currentMultiJobItem
      }
      this.isCreating = true;
      axios.post('document/getDocNlpPreview', data)
      .then(resp => {
        if(resp.data.Status === "OK"){
          this.loadPreviewConfig(resp.data.Data[0]);
        } else {
          this.triggerNotification(`Error - ${resp.data.Message}`,"error")
          this.isCreating = false;
        }
      })
      .catch(err => {
          this.triggerNotification(`Error - ${err}`,"error")
          this.isCreating = false;
      });
    },
    closeDeleteDialog(){
      this.fileDeleteDialog = false;
    },
    openDeleteDialog(){      
      let fileIdList =this.selected.filter(x => x.docLinks.length === 0);
      this.deleteList = fileIdList;
      this.fileDeleteDialog = true;
    },
    downloadFile(fileId){
      let possibleError = false;
      this.isLoading = true;          
        axios
        .get("file/download/", {responseType: 'text', params: { id: fileId}})
        .then(resp => {
            possibleError = true;
            window.open(resp.data,"_blank");
            this.isLoading = false;  
        })
        .catch(err => {
          if (possibleError) {
              alert("Code Error");
          } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
          } else {
              alert(err.response ? err.response.data.message : err);
          }
          this.isLoading = false;
        });
      },    
    deleteFiles(){
      let fileIdList =this.selected.map(file => {
          return file.file_id;
      });

      if (fileIdList.length === 0) {
        this.fileDeleteDialog = false;
        return;
      }

      this.isLoading = true;    
      axios
        .post("file/adminDelete", fileIdList)
        .then((resp) => {
          if(resp.data.Status == "OK"){
            this.$store.dispatch("files/reload");
            this.triggerNotification("Files Deleted Successfully","success");
            this.fetchFileData();
            this.closeDeleteDialog();
            this.selected = [];
          } else {
            this.triggerNotification(resp.data.Message,"error");
          }
            
          this.fileDeleteDialog = false;
          this.isLoading = false;

        })
        .catch(err => {
          this.triggerNotification(err,"error");
          this.fileDeleteDialog = false;   
          this.isLoading = false;           
        });

    },
    deleteGroup(idx){
      let group = this.hierarchyGroups[idx];
      group.items.forEach(i => {
        this.selected.push(i);
      });
      group.items = [];
      this.hierarchyGroups.splice(idx,1);
      this.hierarchyGroups = JSON.parse(JSON.stringify(this.hierarchyGroups));
    },
    closeCreatedDialog(){
      this.showCreatedDocsDialog = false;
      //this.selected = [];
      /*this.hierarchyGroups.forEach(x => {
        x.hierarchyData.treePickerValue = null
      });*/
      //this.hierarchyGroups = [];
      //this.hierarchyGroups = JSON.parse(JSON.stringify(this.hierarchyGroups));
      //this.hierarchyGroups.push(this.addNewHierarchyGroup());
      //this.hierarchyGroups = JSON.parse(JSON.stringify(this.hierarchyGroups));
    },
    openDoc(item){
      this.$emit("openDocument",item);
    },
    triggerNotification(text, type){
        this.errorText = text;
        this.snackColor = type;
        this.showErrorSnack = true;
    },      
    checkCreate(){
      let errors = [];
      let setGroups = this.hierarchyGroups.filter(x => x.items.length > 0);

      if(setGroups.length === 0){
        errors.push("No Documents assigned to Creation Groups ; ")
      }

      let cntr = 1;
      setGroups.forEach(g => {
        if(g.doc_type === ""){
          errors.push(`Document Group ${cntr} does not have a Document Type`);
        }
        if(g.hierarchies.length !== this.hierarchies.length){
          errors.push(`Document Group ${cntr} missing Hierarchy Setup`);
        }

        if(this.itemContainsExcel(g) && !this.$loginState.user.settings.some((x) => x.setting === "xlsx_format")){
          errors.push(`Document Group ${cntr} contains Excel Files & client has no Excel Header Config`);
        }
        
        if(this.itemContainsExcel(g) && g.useExcelData && !g.validationResult){
          errors.push(`Document Group ${cntr} contains Excel Files that mismatch the config`);
        }

        cntr++;
      })

      if(errors.length){
        this.triggerNotification(errors.join("<br/>"),"error");
        return false;
      }

      return true;

    },
    addNewHierarchyGroup(){

      let defaultPresetId = 0;
      let defaultPreset = this.filePresets.find(x => x.is_default);
      if(defaultPreset){
          defaultPresetId = defaultPreset.file_preset_id;
      }

      return {
        "index": this.groupCounter++,
        "items":[],
        "hierarchyData": [... new Set(this.getHierarchyData())],
        "hierarchies":[],        
        "doc_type": "",
        "create_empty": false,
        "selectedTemplate":null,
        "selectedActionNonDefault": false,
        "isActionSelected": false,
        "expanded": true,
        "selectedActionId": null,
        "selectHierarchies": true,
        "selectedPreset": defaultPresetId,
        "translate": false,
        "target_lang": "",
        "useExcelData": false,
        "validationData": null,
        "validatingExcel": false,
        "validationResult": false,
        "requiresDataCapture": false,
        "dataCaptureMap":[],
        "customHierarchy": false
      }
    },
    addHierarchyConfigGroup(){
      this.hierarchyGroups.push(this.addNewHierarchyGroup());
    },
    findHrParent(h,values,path){
      if(path === undefined){
        path = [];
      }

      let node;
      for (node of values) {
        if (node.id === h) {
          if(path.map(x => {return x.id}).indexOf(node.id) === -1){
            path.push(node)
          }
          return {
            "value":node,
            "path": path
          };
        }
        if (node.children) {
          const child = this.findHrParent(h, node.children, path);
          if (child) {
            if(path.map(x => {return x.id}).indexOf(node.id) === -1){
              path.push(node)
            }
            let nextParent = undefined;
            let nextValue = node.id;
            while(nextParent === undefined){
              nextParent = this.findHrParent(nextValue,values,path);
            }
            return {
              "value":child,
              "path": path
            };
          }
        }
      }
    },
    buildPath(subTree, targetNode, currentPath){
        let node;
        for(node of subTree){

            if(node.id === targetNode){
              return currentPath === '' ? node.id.toString() : currentPath + '|' + node.id.toString();
            } else if(node.children){    
              const path = this.buildPath(node.children, targetNode, currentPath === '' ? node.id.toString() : currentPath + '|' + node.id.toString());
              if(path) return path;
            }
        }
    },
    onHrySelect(hierarchyItem, h, value) {
      hierarchyItem.isActionSelected = (value === -999);      

      const newpath = this.buildPath(h.treePickerOptions,value, '');
      let path = newpath.split("|");

      let parent = null;
      let detailPath = [];
      path.forEach(hr => {
        if(parent === null){
          parent = h.treePickerOptions.find(x => x.id.toString() === hr.toString());
          if(parent){
            detailPath.push(parent);
          }
        } else {
          parent = parent.children.find(x => x.id.toString() === hr.toString());
          if(parent){
            detailPath.push(parent);
          }
        }
      })

      let labelPath = detailPath.map(p => {
        return p.label;
      })

      let lowestItem = detailPath.reverse()[0];
      let hr_id = null;
      if(lowestItem !== undefined){
        hr_id = Number(lowestItem.id);
      }

      let hierarchy = {};
      if(!isNaN(hr_id)){
        hierarchy = h.values.find(x => x.value === hr_id);
        if(!hierarchy){
          hierarchy = h.treePickerOptions.find(x => x.id === hr_id);
          hierarchy.value = hierarchy.id;
          hierarchy["ht_id"] = h.ht_id;
        }
        hierarchy["ht_id"] = h.ht_id;
        hierarchy.hr_id = hierarchy.value;
        hierarchy["path"] = labelPath;
      } else {
        hierarchy["ht_id"] = h.ht_id;
        hierarchy["path"] = labelPath;
        hierarchy["hr_id"] = 0;
      }


      let hrItem = hierarchyItem.hierarchies.find(x => x.ht_id === h.ht_id);
      if(hrItem === undefined){
        hierarchyItem.hierarchies.push(hierarchy);
      } else {
        hierarchyItem.hierarchies.splice(hierarchyItem.hierarchies.indexOf(hrItem),1);
        hierarchyItem.hierarchies.push(hierarchy);
      }
      
      this.hierarchyGroups = JSON.parse(JSON.stringify(this.hierarchyGroups));
    },   
    createDocs(){

      let valid = this.checkCreate();
      if(valid){
        this.isCreating = true;
        let sendData = this.hierarchyGroups.map(h => {
            let action = null;
            if (h.selectedTemplate.lifecyclesHT.length) {
              let htTrigger = h.hierarchies.find(
                (ht) => ht.ht_id === h.selectedTemplate.lifecyclesHT[0].ht_id_empty_trigger
              );
              if (htTrigger.hr_id === -999) {
                action = h.selectedTemplate.lifecyclesHT[0];
              }
            }
            if (!action && h.selectedTemplate.lifecycles.length) {
              if (h.selectedTemplate.lifecycles.length === 2) {
                action =
                  h.selectedTemplate.lifecycles[
                    h.selectedActionNonDefault ? 1 : 0
                  ];
              } else {
                action = h.selectedTemplate.lifecycles.find(
                  (x) => x.lifecycle_action_id === h.selectedTemplate.selectedActionId
                );
              }
            }   

            let dcFields = !h.requiresDataCapture ? null : h.dataCaptureMap.map(x => {
              switch(x.method){
                case "form":
                  return {
                    "method":"form",
                    "data_capture_field": x.data_capture_field, 
                    "value": x.value, 
                    "type": x.type === "" ? "value" : x.type
                  }     
                default:
                    return x;             
              }
            });

            dcFields?.forEach((dc) => {
              if (dc.method === "requisition") {
                dc.requisitions = [
                  {
                    isNew: true,
                    requisition_number: dc.value,
                  },
                ];
              }
            });
                   
            return {
              "doc_type":h.doc_type,
              "create_empty": !h.create_empty,  //this used to be default was to try to parse - default is now not to so reversing logic + changing label
              "parseProfile": h.selectedPreset,
              "files": h.items.map(f => {
                return {
                  "file_id": f.file_id,
                  "file_name": f.file_name,
                  "file_extension": f.file_extension,
                  "rowStart": f.startRow,
                  "rowEnd": f.endRow
                }
              }),
              "hierarchies": h.hierarchies,
              "action": action,
              "target_lang": h.translate ? h.target_lang : null,
              "use_excel_columns": h.useExcelData,
              "dataCaptureMap": dcFields
            };
        });

        axios.post('/document/bulkCreateDocs', sendData)
        .then(resp => {
            if(resp.status === 200 || resp.status === 201){
              if(this.useGateway){
                this.consoleLog.push("Document Creation requested queued");
                this.triggerNotification(`Document Creation requested queued`,"success");
                this.hierarchiesDialog = false;
                this.isCreating = false;
                this.awaitingResponse = true;
                this.progressDialog = true;
              } else {
                this.triggerNotification(`${resp.data.length} Documents Created`,"success")
                this.isCreating = false;
                this.showCreateDocs(resp.data);
              }

            } else {
              this.triggerNotification(`Error - ${resp.statusText}`,"error")
              this.isCreating = false;
            }

        })
        .catch(err => {
            this.triggerNotification(`Error - ${err}`,"error")
            this.isCreating = false;
        });
      }



    },
    showCreateDocs(data){
      this.createdDocs = [];
      this.createdDocs = data;
      this.hierarchiesDialog = false;
      this.showCreatedDocsDialog = true;
    },
    getHierarchyData(){
      return this.$store.state.hierarchies.hierarchies.map(
          (h) => {
            switch (h.label.toLowerCase()) {
              case "geography":
              case "location":
                h.icon = "mdi-map-marker-outline";
                break;
              default:
                h.icon = "mdi-menu-down";
                break;
            }
            return h;
          }
        );
    },
    setData() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.useTreePicker = this.$loginState.user.settings.find(
          (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
        );
        this.hierarchyData = this.getHierarchyData();

        this.canAddHierarchy = this.$store.state.hierarchies.canAddHierarchy;
        this.hierarchyGroups = [this.addNewHierarchyGroup()]
      }

      this.enabledTranslate = this.$loginState.user.settings.some((s) => s.setting === "UseTranslation" && s.value === "true");

      axios.get("document/getTemplatesWithParts")
        .then((resp) => {
            if (resp.data.Status === "OK") {
              this.excelDataFieldTargets = [
                { header: 'Default' },
                { divider: true },
                {"label":"Requisition ID", "text":"Requisition ID", "value":"requisition_number", "tmpl_part_id":0},
                {"label":"Job Content", "text":"Job Content", "value":"job", "tmpl_part_id":0},
                {"label":"Document Name", "text":"Document Name", "value":"doc_name", "tmpl_part_id":0}
              ];              
              resp.data.Data.forEach(t => {
                this.excelDataFieldTargets.push({ header: t.doc_type });
                this.excelDataFieldTargets.push({ divider: true });
                t.parttypes.forEach(p => {
                    p.parts.forEach((tp,idx) => {
                      let type = p.parts.length > 1 ? `${tp.type} ${idx+1}` : tp.type;
                      this.excelDataFieldTargets.push({ label: type + ` (${t.doc_type_abbrev})`, text: type, value: type + ` (${t.doc_type_abbrev})`, tmpl_part_id: tp.id });
                    })
                  });
              });
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
        });
    },    
    filesUploadedCallback(){
      this.$store.dispatch("files/reload");
      this.fetchFileData();  
    },
    loadDataCaptureItems(item, selectedTemplate){
      let dc = selectedTemplate.lifecycles[0].dataCaptures;//.filter(x => x.method === 'form');
      dc.forEach(c => {
        switch(c.method){
          case "form":
            item.dataCaptureMap.push({"method":"form","data_capture_field":c.input_label,"value":"", "type":""}); 
            break;  
          default:
            item.dataCaptureMap.push({ ...c, "type":"" });  
            break;      
        }
      })

      if(this.itemContainsExcel(item)){
      let allExcel = item.items.filter(x => x.file_extension === "xlsx" || x.file_extension === "xls");
        axios.get("file/getSpreadsheetInfo",  { params: { ids:  allExcel.map(x => { return x.file_id }), sampleCount: 1, config: JSON.stringify(this.excelConfiguration.config)}})
        .then((resp) => {

          let colHeaders = resp.data[0].headers;
          item.dataCaptureMap.forEach(c => {
            c["col_headers"] = colHeaders
          });
          
          item.requiresDataCapture =  true;

        })
        .catch(err => {
          console.log(err);
        });
      } else {
        item.requiresDataCapture =  true;
      }


    },
    docTypeChange(item){
      let selectedTemplate = this.$loginState.user.createDocumentOptions.find(
          (a) => a.tmpl_name === item.doc_type
        );
      this.selectedActionNonDefault = false;        
      if (selectedTemplate) {
        item.dataCaptureMap = [];
        item.requiresDataCapture =  false;
        if (selectedTemplate.lifecycles.length === 1) {
          selectedTemplate.selectedActionId = selectedTemplate.lifecycles[0].lifecycle_action_id;
          if(selectedTemplate.lifecycles[0].dataCaptures){
            this.loadDataCaptureItems(item, selectedTemplate);
          }
          /*let dcs = selectedTemplate.lifecycles[0].dataCaptures.some(x => x.method === "form");
          if(dcs === true){
            this.loadDataCaptureItems(item, selectedTemplate);
          }*/
        }
        item.selectedTemplate = selectedTemplate;
        let htTrigger = selectedTemplate.lifecyclesHT.length
          ? selectedTemplate.lifecyclesHT[0]
          : null;
        item.hierarchyData.forEach((ht) => {
          let trigger = ht.values.findIndex((v) => v.isActionTrigger);
          if (trigger >= 0) {
            ht.values.splice(trigger, 1);
          }
          trigger = ht.treePickerOptions.findIndex((v) => v.isActionTrigger);
          if (trigger >= 0) {
            ht.treePickerOptions.splice(trigger, 1);
          }
          if (htTrigger && ht.ht_id === htTrigger.ht_id_empty_trigger) {
            ht.values.unshift({
              hr_id: -999,
              text: htTrigger.lifecycle_selection_text,
              isActionTrigger: true,
            });
            ht.treePickerOptions.unshift({
              id: -999,
              label: htTrigger.lifecycle_selection_text,
              isActionTrigger: true,
            });
          }
        });        
      }
    },
    openHierarchyDialog(){

      this.isCreating = true;
      this.docTypes = this.$loginState.user.createDocumentOptions.map(d => {
        return d.tmpl_name;
      });

      let allExcel = this.selected.filter(x => x.file_extension === "xlsx" || x.file_extension === "xls");

      if(allExcel.length > 0){
        axios
        .get("file/getSpreadsheetInfo",  { params: { ids:  allExcel.map(x => { return x.file_id }) }})
        .then(resp => {
          resp.data.forEach(r => {
            let item = this.selected.find(x => x.file_id === r.file_id);
            item.rowCount = r.rowCount;
            item.headers = r.headers;
            item.startRow = 1;
            item.endRow = 10;
          })
          this.hierarchiesDialog = true;
          this.isCreating = false;
        })
        .catch(err => {
          console.log(err);
        });
      } else {
        this.hierarchiesDialog = true;
        this.isCreating = false;
      }

    },
    closeHierarchyDialog(){
      this.hierarchiesDialog = false;
    },
    openCreateDialog(){
      this.docTypes = this.$loginState.user.createDocumentOptions.map(d => {
        return d.tmpl_name;
      });
      this.fileCreateDialog = true;
    },
    closeCreateDialog(){
      this.fileCreateDialog = false;
    },
    fetchFileData(){
      let possibleError = false;
      this.isLoading = true;
          axios
          .get("file/list/")
          .then(resp => {
            if(resp.status === 200){
              possibleError = true;
              this.fileItems = resp.data;
              this.filteredFileItems = resp.data;
              this.isLoading = false;
              this.disableFileUpload = true;
              this.multiUpload = "false";
              this.$nextTick(() => {
                this.disableFileUpload = false;
                this.multiUpload = "true";
              });
            } else {
              this.isLoading = false;
            }
                
          })
          .catch(err => {
              if (possibleError) {
                  alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                  this.$emit("sessionExpired", err);
              } else {
                  alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.isLoading = false;
          });
    },
    resolveIcon(extension){
        switch(extension){
            case "xlsx":
            case "xls":
                return "mdi-microsoft-excel";
            case "doc":
            case "docx":
            case "rtf":
                return "mdi-microsoft-word";
            case "txt":
            case "log":
                return "mdi-file-document-outline";
            case "pdf":
                return "mdi-file-pdf-box";
        }
    },
    resolveHeaderStyle(extension){
        switch(extension){
            case "xlsx":
            case "xls":
                return "#3cc542ad";
            case "doc":
            case "docx":
            case "rtf":
                return "#327bb5c7";
            case "txt":
            case "log":
                return "#ff23138f";
            case "pdf":
                return "#ff23138f";
        }
    },
    resolveSelectedBackground(extension){
      switch(extension){
            case "xlsx":
            case "xls":
                return "#3cc54229";
            case "doc":
            case "docx":
            case "rtf":
                return "#0037ff1c";
            case "txt":
            case "log":
                return "#ff23138f";
            case "pdf":
                return "#ff23138f";          
      }
    },        
  }
}
</script>
<style scoped>
.wrapclass {
  max-width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nlpMarkup:hover{
  cursor:pointer;
}
</style>
