<template>
  <nav aria-label="pagination">
    <ul class="pagination__list">
      <li>
        <a v-if="pageFrom > 0" @click="prevPage" aria-label="Previous page" class="prevNext"
          href="#"><v-icon>mdi-chevron-left</v-icon></a>
        <v-icon v-else aria-label="Previous page" class="mr-2">mdi-chevron-left</v-icon>
      </li>
      <li v-for="(page, index) in pageOptions.pages" :key="index" class="hidden-sm-and-down">
        <a v-if="page == currentPage" class="page selected" :aria-label="`page ${page}`" aria-current="page" href="#">{{
          page }}</a>
        <a v-else-if="!isNaN(page)" @click="setPage(page, $event)" class="page" :aria-label="`page ${page}`" href="#">{{ page
          }}</a>
        <span v-else>{{ page }}</span>
      </li>
      <li>
        <a v-if="currentPage < pageOptions.totalPages" @click="nextPage" class="prevNext" aria-label="Next page"
          href="#"><v-icon>mdi-chevron-right</v-icon></a>
        <v-icon v-else aria-label="Next page" class="ml-2">mdi-chevron-right</v-icon>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "DocumentsWithPartsNew",
  components: {},
  props: {
    totalItems: { type: Number, required: true },
    pageSize: { type: Number, required: true },
    currentPage: { type: Number, required: true },
    maxPages: { type: Number, required: false, default: 8 },
  },
  data: function () {
    return {};
  },
  watch: {},
  computed: {
    pageOptions() {
      // calculate total pages
      let totalPages = this.pageSize < 0 ? 1 : Math.ceil(this.totalItems / this.pageSize);
      let currentPage = this.currentPage;

      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      let startPage, endPage;
      if (totalPages <= this.maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(this.maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(this.maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = this.maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - this.maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );

      if (pages.length) {
        if (startPage > 1) {
          pages[0] = 1;
          pages[1] = "...";
        }

        if (endPage < totalPages) {
          pages[pages.length - 1] = "...";
          pages[pages.length] = totalPages;
        }
      }

      // return object with all pager properties required by the view
      return {
        totalPages: totalPages,
        pages: pages,
      };
    },
    pageFrom() {
      return this.pageSize < 0 ? 0 : this.pageSize * (this.currentPage - 1);
    },
    pageTo() {
      if (this.pageSize < 0)
        return this.totalItems;

      const pageTo = this.pageFrom + this.pageSize;
      if (pageTo > this.totalItems) return this.totalPages;
      else return pageTo;
    },
  },
  created() { },
  methods: {
    setPage(page, ev) {
      ev?.preventDefault();
      if (!isNaN(page)) this.$emit("pageNavigation", page);
    },
    prevPage(ev) {
      ev?.preventDefault();
      if (this.pageFrom > 0) this.setPage(this.currentPage - 1);
    },
    nextPage(ev) {
      ev?.preventDefault();
      if (this.currentPage < this.pageOptions.totalPages)
        this.setPage(this.currentPage + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";

ul.pagination__list {
  list-style: none;
  display: flex;
  padding: 0;

  .page {
    border: solid 1px rgba(78, 93, 120, 0.1);
    border-radius: 20px;
    padding: 4px 12px 2px 12px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    background-color: transparent;
    margin: 0 3px 4px 3px;
    max-width: 20px;
  }
  .prevNext {
    padding: 4px 6px 2px 6px;
    border-radius: 20px;
  }
}

.v-application.theme--light ul.pagination__list {
  .page {
    color: $input-icon-color;

    &.selected {
      background-color: $secondary-color-light;
    }

    &:hover,
    &:focus {
      outline: 0;
      border: solid 1px rgba(78, 93, 120, 0.6);
      background-color: $selected-item-background-light;
    }
  }
  .prevNext {
    &:hover,
    &:focus {
      outline: 0;
      background-color: $selected-item-background-light;
    }
  }
}

.v-application.theme--dark ul.pagination__list {
  .page {
    color: white;

    &.selected {
      background-color: $secondary-color-dark;
    }

    &:hover,
    &:focus {
      outline: 0;
      border: solid 1px rgba(129, 140, 161, 0.6);
      background-color: $secondary-background-dark;
    }
  }
  .prevNext {
    &:hover,
    &:focus {
      outline: 0;
      background-color: $secondary-background-dark;
    }
  }
}
</style>
