<template>
  <div>
    <v-row v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary" size="50">
      </v-progress-circular>
    </v-row>
    <v-row class="doc-container">
      <v-col v-for="d in data" :key="d.doc_id">
        <v-alert
          v-if="multiDoc && hierarchySuggestions && hierarchySuggestions.show"
          border="top"
          colored-border
          elevation="2"
          class="mb-8"
          ><v-row>
            <v-col
              ><h4>
                AI Generated
                {{ hierarchySuggestions.hierarchyType.ht_name }} Suggestions:
              </h4></v-col
            >
          </v-row>
          <div v-if="d.suggestionProcessed" class="pa-4">
            Document Updated with selected hierarchy <v-icon color="green">mdi-check-circle</v-icon>
          </div>
          <div v-else>
            <v-row v-if="d.suggestions.length" dense>
              <v-col
                v-for="l in hierarchySuggestions.hierarchyType.h_levels"
                :key="l"
                style="text-align: left"
                ><h4>{{ l }}</h4></v-col
              >
              <v-col cols="2"></v-col>
            </v-row>
            <v-row v-else>
              <v-col v-if="isLoadingSuggestions"
                >Identifying
                {{
                  hierarchySuggestions.hierarchyType.h_levels.join(" / ")
                }}s....
                <v-progress-circular indeterminate color="primary" size="30">
                </v-progress-circular
              ></v-col>
              <v-col v-else
                >No relevant
                {{ hierarchySuggestions.hierarchyType.h_levels.join(" / ") }}s
                cound be identified</v-col
              >
            </v-row>
            <v-row dense v-for="(s, si) in d.suggestions" :key="'hs' + si">
              <v-col style="text-align: left; font-size: 12px"
                >{{ s.family_group
                }}<v-icon
                  v-if="!s.original_family_group"
                  color="red"
                  title="New Job Family Group"
                  >mdi-alert-decagram</v-icon
                ></v-col
              >
              <v-col style="text-align: left; font-size: 12px"
                >{{ s.family }}
                <v-icon
                  v-if="!s.original_family"
                  color="red"
                  title="New Job Family"
                  style="padding-left: 15px"
                  >mdi-alert-decagram</v-icon
                ></v-col
              >
              <v-col style="text-align: left; font-size: 12px"
                >{{ s.title
                }}<v-icon
                  v-if="!s.original_title"
                  color="red"
                  title="New Job Title"
                  >mdi-alert-decagram</v-icon
                ></v-col
              >
              <v-col cols="2"
                ><v-btn small @click="assignHierarchy(s, d)"
                  >Assign</v-btn
                ></v-col
              >
            </v-row>
          </div></v-alert
        >
        <v-alert
          v-if="multiDoc"
          border="top"
          colored-border
          elevation="2"
          color="primary"
          class="mb-8 text-center"
        >
          <v-row dense>
            <v-col cols="1" v-if="getMatchPctContent(d)"
              ><v-avatar
                v-if="getMatchPctContent(d)"
                color="teal"
                size="30"
                title="Similar content"
              >
                <span class="white--text" style="font-size: 12px">{{
                  getMatchPctContent(d) + "%"
                }}</span>
              </v-avatar></v-col
            >
            <v-col cols="1" v-else-if="getMatchPctName(d)">
              <v-avatar
                color="indigo"
                size="30"
                title="Same title, different content"
              >
                <span class="white--text" style="font-size: 12px">{{
                  getMatchPctName(d) + "%"
                }}</span>
              </v-avatar></v-col
            >
            <v-col
              cols="8"
              @click="openItem(d)"
              :style="d.reference || d.file_id ? 'cursor: pointer' : ''"
            >
              {{ d.tmpl_name || d.doc_name }}
              {{ d.reference ? `/ ${d.reference}` : "" }}
              <v-btn small icon v-if="d.file_id" title="Download File"
                ><v-icon>download</v-icon></v-btn
              >
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <v-chip
                small
                class="status-chip"
                label
                :color="d.status_colour"
                >{{ d.status }}</v-chip
              >
              <v-icon
                v-if="showWorkflowActions"
                @click="openDocActionsMenuContext($event, d)"
                >mdi-dots-vertical</v-icon
              >
            </v-col>
            <v-col cols="12">
              Created {{ d.created_date }} by {{ d.created_by }}
            </v-col>
          </v-row>
        </v-alert>

        <template v-if="d.doc_id && !d.file_id">
          <v-row
            v-for="(tp, tpi) in d.templateParts"
            :key="tp.tp_id"
            :class="['mb-2']"
          >
            <v-col
              v-for="(v, vi) in tp.versions"
              :key="v.activity_id"
              class="mx-2"
            >
              <v-alert
                v-if="!multiDoc && tpi === 0"
                border="top"
                colored-border
                elevation="2"
                color="primary"
                class="mb-8 text-center"
              >
                <v-chip
                  small
                  class="status-chip mx-2"
                  label
                  :color="d.versions[vi].status_colour || d.status_colour"
                  >{{ d.versions[vi].status || d.status }}</v-chip
                >
                Updated on {{ d.versions[vi].activity_date }} by
                {{ d.versions[vi].activity_by }}
                <div style="float:right">
                  <v-progress-circular indeterminate size="30" color="primary" v-if="d.versions[vi].exporting">
                  </v-progress-circular>
                  <v-menu v-else offset-y bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="float-right"
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="exportVersion(d, vi, 'pdf')" >
                        <v-list-item-title>
                          Export to PDF
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="exportVersion(d, vi, 'docx')" >
                        <v-list-item-title>
                          Export to Word
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-alert>

              <v-row v-for="dp in v" :key="dp.dp_id" :class="tp.partClass">
                <v-tooltip
                  top
                  v-for="(dpa, dpai) in dp.attrs"
                  :key="dpa.dpa_id"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col
                      class="pa-0"
                      :cols="tp.attrs[dpai]?.cols || 12"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-chip
                        v-if="
                          highlightChanges && dp.essential_changes
                            ? dp.essential_changes
                            : dp.essential
                        "
                        small
                        class="float-right mb-1"
                      >
                        <span
                          v-html="
                            highlightChanges && dp.essential_changes
                              ? dp.essential_changes
                              : dp.essential
                          "
                        ></span>
                      </v-chip>
                      <span
                        v-if="
                          highlightChanges && dpa.changes
                            ? dpa.changes
                            : dpa.text
                        "
                        v-html="
                          highlightChanges && dpa.changes
                            ? dpa.changes
                            : dpa.text
                        "
                        :class="{
                          ListPart:
                            dpai === 0 &&
                            (tp.moveable || dp.type === 'listitem'),
                        }"
                      ></span>
                    </v-col>
                  </template>
                  <span>{{ dp.details }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <div v-else-if="d.file_id">
          <div v-if="d.contentLoading" class="d-flex justify-center">
            <v-progress-circular indeterminate size="50" color="primary">
            </v-progress-circular>
          </div>
          <span v-else v-html="d.contentHtml"></span>
        </div>
      </v-col>
    </v-row>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <DocActionsMenu
      :context="docActionsMenuContext"
      @openDocument="openDocument"
      @copyDocument="$emit('copyDocument', $event)"
    ></DocActionsMenu>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import DocActionsMenu from "@/components/common/DocActionsMenu";
import dayJS from "dayjs";
import utils from "@/common/utils.js";
import documentExport from "@/common/documentExport.js";
import nlpUtils from "@/common/nlpUtils.js";

export default {
  name: "DocumentVersionCompare",
  components: {
    ResponseHandler,
    DocActionsMenu,
  },
  props: {
    compareList: { type: Array, required: true },
    highlightChanges: { type: Boolean, default: true },
    hideCompareVersion: { type: Boolean, default: false },
    multiDoc: { type: Boolean, default: false },
    showWorkflowActions: { type: Boolean, default: false },
    hierarchySuggestions: { type: Object },
  },
  data: function () {
    return {
      data: [],
      isLoading: false,
      isLoadingSuggestions: false,
      response: null,
      utils: utils,
      nlpUtils: nlpUtils,
      docActionsMenuContext: {
        show: false,
        busy: false,
        document: null,
        posX: 0,
        posY: 0,
        JA_DEMO: true,
      },
    };
  },
  updated() {},
  mounted() {
    this.getData();
  },
  computed: {},
  watch: {},
  methods: {
    getData(existingSuggestions) {
      this.isLoading = true;
      const params = {
        compareDocs: this.compareList
          .filter((d) => d.doc_id && !d.file_id)
          .map((d) => {
            return {
              doc_id: d.doc_id,
              activity_id: d.activity_id,
              viewAction: this.$store.getters["docs/docsList"].find((x) => x.doc_id === d.doc_id)?.viewAction,
            }
          }),
      };
      axios
        .post("document/documentVersions", params)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            let data = resp.data.Data;
            data.forEach((d) => {
              d.created_date = dayJS(d.created_date).format("DD MMM YYYY");
              if (this.hideCompareVersion && d.versions.length > 1) {
                d.versions.splice(0, 1);
                d.templateParts.forEach((tp) => {
                  tp.versions.splice(0, 1);
                });
              }
              d.versions.forEach(
                (v) => {
                  v.activity_date = dayJS(v.activity_date).format(
                    "DD MMM YYYY HH:mm"
                  );
                  v.exporting = false;
                }
              );
            });
            let dat = [
              ...data,
              ...this.compareList
                .filter((d) => d.file_id)
                .map((d) => {
                  d.contentHtml = null;
                  this.fetchFileData(d);
                  return d;
                }),
            ];
            if (this.hideCompareVersion) {
              dat.forEach((d) => {
                let source = this.compareList.find(
                  (x) => x.doc_id === d.doc_id
                );
                if (source) {
                  d.match_pct_content === source.match_pct_content;
                  d.match_pct_name === source.match_pct_name;
                }
              });
            }
            if (this.hierarchySuggestions?.show) {
              dat.forEach((d) => {
                let e = existingSuggestions
                  ? existingSuggestions.find((e) => e.doc_id === d.doc_id)
                  : null;
                d.suggestions = e ? e.suggestions : [];
                d.suggestionProcessed = e ? e.suggestionProcessed : false;
              });
              if (!existingSuggestions) this.getSuggestion();
            }
            this.data = dat;
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    getSuggestion() {
      this.isLoadingSuggestions = true;
      const docs = this.compareList
        .filter((d) => d.doc_id)
        .map((d) => {
          return {
            doc_id: d.doc_id,
            title: d.doc_name,
          };
        });
      axios
        .post("document/hierarchysuggestion", {
          docs: docs,
          ht_id: this.hierarchySuggestions?.hierarchyType.ht_id,
        })
        .then((resp) => {
          if (resp.data.Status === "OK") {
            let data = resp.data.Data;
            if (this.hierarchySuggestions?.show) {
              this.data.forEach(
                (d) =>
                  (d.suggestions =
                    data.docs.find((x) => x.doc_id === d.doc_id)?.suggestions ||
                    [])
              );
            }
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          this.isLoadingSuggestions = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoadingSuggestions = false;
        });
    },
    fetchFileData(file) {
      file.contentLoading = true;
      nlpUtils.getDocText(file.file_id, file.row_id).then((response) => {
        if (response.status == 200 && (response.data.rawText || response.data.rawData)) {
          let sourceText =
            response.data.rawText?.match(/<[a-z][\s\S]*>/i)?.length > 0
              ? response.data.rawText
              : response.data.rawData;
          file.contentHtml = sourceText
            ?.replaceAll("<em>", "")
            ?.replaceAll("</em>", "");
        } else {
          this.response = response;
        }
        file.contentLoading = false;
      });
    },
    openDocActionsMenuContext(event, document) {
      event.preventDefault();
      let doc = {
        doc_id: document.doc_id,
        reference: document.reference,
        doc_name: document.doc_name,
        doc_type: document.doc_type,
      };
      this.docActionsMenuContext.document = doc;
      this.docActionsMenuContext.posX = event.clientX;
      this.docActionsMenuContext.posY = event.clientY;
      this.docActionsMenuContext.show = false;
      this.$nextTick(() => {
        this.docActionsMenuContext.show = true;
      });
    },
    openItem(item) {
      if (item.reference) this.openDocument(item);
      else if (item.file_id) this.nlpUtils.downloadFile(item.file_id);
    },
    openDocument(item) {
      if (!item.reference) return;

      this.$emit("openDocument", {
        system_number: utils.removeTags(item.reference),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        viewAction: item.viewAction,
      });
    },
    exportVersion(item, index, format) {
      this.exporting = true;
      const version = item.versions[index]
      version.exporting = true;
      const compareVersion = this.compareList.filter(d => d.doc_id === item.doc_id)[0];
      const thisVersion = this.compareList.filter(d => d.doc_id === item.doc_id)[this.hideCompareVersion ? index + 1 : index];
      const params = {
        compareDocs: [
            `${item.doc_id}_${compareVersion.activity_id ? compareVersion.activity_id : ""}`,
            `${item.doc_id}_${thisVersion?.activity_id ? thisVersion.activity_id : ""}`,
        ],
        format: format,
        highlightChanges: this.highlightChanges
      };

      axios
        .get("document/documentVersionsExport", { params: params,  responseType: 'arraybuffer' })
        .then((resp) => {   
          const docName = `${item.reference}_${version.status}Version_${dayJS(version.activity_date).format("YYYYMMDDHHmm")}.${format}`;
          utils.downloadFile(resp.data, docName, documentExport.getMimeType(format));
          version.exporting = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = { message: "Unexpected Error" };
          }
          version.exporting = false;
        });
    },
    getMatchPctContent(d) {
      let pct = this.compareList.find(
        (x) => x.doc_id === d.doc_id
      )?.match_pct_content;
      if (pct) return Number.parseFloat(pct).toFixed();
      else return false;
    },
    getMatchPctName(d) {
      let pct = this.compareList.find(
        (x) => x.doc_id === d.doc_id
      )?.match_pct_name;
      if (pct) return Number.parseFloat(pct).toFixed();
      else return false;
    },
    assignHierarchy(selected, d) {
      // transpose service response into something more generic
      let data = {
        ht_id: this.hierarchySuggestions.hierarchyType.ht_id,
        values: [
          {
            level: 1,
            existing: selected.original_family_group,
            name: selected.family_group,
          },
          {
            level: 2,
            existing: selected.original_family,
            name: selected.family,
          },
          { level: 3, existing: selected.original_title, name: selected.title },
        ],
        doc_id: d.doc_id,
        callback: this.assignHierarchyCompleted,
      };
      this.$emit("assignHierarchy", data);
    },
    assignHierarchyCompleted(doc_id) {
      let existing = this.data.find((d) => d.doc_id === doc_id);
      if (existing) existing.suggestionProcessed = true;
      let previous = this.data.map((d) => {
        return {
          doc_id: d.doc_id,
          suggestions: d.suggestions,
          suggestionProcessed: d.suggestionProcessed,
        };
      });
      this.getData(previous);
    },
  },
};
</script>
<style scoped lang="scss">
.doc-container {
  display: flex;
  overflow: auto;
  flex-wrap: nowrap;
  max-height: calc(90vh - 120px) !important;
  > .col {
    min-width: 100%;
    width: auto;
    flex-grow: 1;

    @media only screen and (min-width: 960px) {
      min-width: 50%;
    }

    @media only screen and (min-width: 1904px) {
      min-width: 33.3%;
    }

    @media only screen and (min-width: 2500px) {
      min-width: 25%;
    }
  }
}

.HeaderPart,
.CentralHeaderPart {
  font-weight: 700;
  font-size: 18px;
}

.CentralTitle,
.Title {
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.6;
}

.DocumentHdr {
  font-size: 1.25rem;
  font-style: italic;
}

.CentralHeaderPart,
.CentralTitle {
  text-align: center;
}

.AttrHeaderPart {
  font-weight: bold;
  font-size: 1rem;
  line-height: normal;
}

.ListPart {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}
</style>
